import React, {useState, useEffect, useContext} from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

export default function EditLanguageDropdown(props) {
    const { onChange } = props;
    const { size, placeholderText, label, fieldChange, defaultValue } = props;
    const { fieldKey } = props;
    const disabled = props.disabled;

    const [languageList, setLanguageList] = useState([]);

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        if(languageList == null || languageList.length === 0) {
            aldersgateContext.GetFromAPI('translation/list/ul').then((success, err) => {

            if(success) {
                let langList = [];
                success.forEach((row, index) => {
                    langList.push({value:row.lc, label:row.displayName});
                });

                setLanguageList(langList);
            }
            }).catch((error) => {
                console.log("Error: " + error);
            });
        }
    }, []);

    const onTagsChange = (event, values) => {
        if(values == null || values.value == null) {
            return;
        }

        onChange(values.value);
    }

    let defaultVal = null;
    //if defaultValue is in the options, set it as the default value
    if(defaultValue !== null && defaultValue !== undefined) {
        for(var i = 0; i < languageList.length; i++) {
            if(languageList[i].value === defaultValue) {
                defaultVal = languageList[i];
            }
        }
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={languageList}
            onChange={onTagsChange}

            disabled={disabled}
            value={defaultVal}

            renderInput={(params) => (
                <TextField
                  disabled={disabled}
                  {...params}
                  label={label}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  variant="standard"
                />
              )}
            />
        </FormControl>
    )
}