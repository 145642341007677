import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';

export default function EditUserEmail(props) {

    const { elementData, OnElementChange } = props;

    const [fieldData, setFieldData] = useState(null);
    const [lastValue, setLastValue] = useState(null)
    const [editEmail, setEditEmail] = useState(false);

    useEffect(() => {
        setFieldData(elementData);
    }, [elementData]);

    const handelStartEdit = () => {
        setLastValue(fieldData);
        setEditEmail(true);
    }

    const handleCancelEdit = () => {
        setFieldData(lastValue);
        setEditEmail(false);
    }

    const handleSaveEdit = () => {
        OnElementChange(fieldData);
        setEditEmail(false);
    }


    if(editEmail) {
        return (
            <Stack direction="row" justifyContent="flex-start">
                <TextField
                    id="email"
                    label="Email"
                    variant="standard"
                    size='small'
                    value={fieldData}
                    onChange={(e) => setFieldData(e.target.value)}
                    sx={{width: '275px'}}
                    
                />
                <IconButton aria-label="Cancel" onClick={() => {handleCancelEdit()}}><CancelIcon color='error' /></IconButton>
                <IconButton aria-label="Save" onClick={() => {handleSaveEdit()}}><AddCircleIcon color='success' /></IconButton>
            </Stack>
        );
    } else {
        return (
            <Typography variant="body1" component="p" onClick={() => {handelStartEdit()}}>
                {fieldData ? fieldData : 'No Email'}
            </Typography>
        );
    }
}