import React from 'react';

import RegHeader from '../EventRegistration/RegHeader';
import TranslationContainer from '../../localization/TranslationContainer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { IconedText } from '../';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

import FilterIcon from '@mui/icons-material/Filter';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';

import LocationOnIcon from '@mui/icons-material/LocationOn';
  
  export default function CreateEventConfirm(props) {
    
    //const eventMediumTitle = props.values.eventMedium + " Event";

    var location = (props.values.state) ? (props.values.city + ', ' + props.values.state  + ', ' + props.values.countryID) : (props.values.city + ', ' + props.values.countryID);

    let eventData = {...props.values};
    let setEventName = props.setEventName;

    const translationContainer = new TranslationContainer();

    if(eventData.eventName === "") {
      let eventName = eventData.eventType;
      if(eventData.city !== "") {
        eventName += " - " + eventData.city;
      }

      setEventName(eventName);
    }

    function GetMediumIcon(medium) {
      switch (medium) {
        case "Online":
          return <TapAndPlayIcon sx={{fontSize: 45}} color="action" />;
          case "In-Person":
            return <GroupIcon sx={{fontSize: 45}} color="action" />;
            case "Hybrid":
              return <StreetviewIcon sx={{fontSize: 45}} color="action" />;
        default:
          break;
      }
  }
  
  function GetTypeIcon(trainingType) {
    switch (trainingType) {
      case "Module 1":
        return <Filter1Icon sx={{fontSize: 40}} color="action" />;
        case "Module 2":
          return <Filter2Icon sx={{fontSize: 40}} color="action" />;
          case "Journey":
            return <FilterIcon sx={{fontSize: 40}} color="action" />;
      default:
        break;
    }
  }
  
  
  const GetMediumText = (medium) => {
    switch (medium) {
      case "Online":
        return translationContainer.GetTranslation("tables", "event-info-online", "This event takes place online");
        case "In-Person":
          return translationContainer.GetTranslation("tables", "event-info-in-person", "This is an In-Person event");
          case "Hybrid":
            return translationContainer.GetTranslation("tables", "event-info-hybrid", "Part in-person and part online");
      default:
        break;
    }
}

const GetEventMediumTitle = (medium) => {
    switch (medium) {
        case "Online":
            return translationContainer.GetTranslation("tables", "online", "Online Event");
        case "In-Person":
            return translationContainer.GetTranslation("tables", "in-person", "In-Person Event");
        case "Hybrid":
            return translationContainer.GetTranslation("tables", "hybrid", "Hybrid Event");
        default:
            break;
    }
}
  
  function GetEventTypeText(trainingType) {
    switch (trainingType) {
      case "Module 1":
        return translationContainer.GetTranslation("tables", "hm1", "History Makers Module 1");
      case "Module 2":
        return translationContainer.GetTranslation("tables", "hm2", "History Makers Module 2");
      case "Journey":
        return translationContainer.GetTranslation("tables", "hmj", "History Makers Journey");
      case "Mobilization":
        return translationContainer.GetTranslation("tables", "mobilization", "Flight School");
      default:
        break;
    }
  }
      

    return (
      <Box>
        <Grid container spacing={3}>
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                
            </Grid>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                  <RegHeader isLoaded='false' eventData={eventData} />

                  <Grid container >
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Box sx={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center'}} m={1.0}>
                        <IconedText icon={GetMediumIcon(props.values.eventMedium)} title={GetEventMediumTitle(props.values.eventMedium)}>{GetMediumText(props.values.eventMedium)}</IconedText>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Box sx={{display: 'flex', justifyContent: 'center'}} m={1.0}>
                        <IconedText icon={GetTypeIcon(props.values.eventType)} title={translationContainer.GetTranslation("tables", "event-type", "Event Type")}>{GetEventTypeText(props.values.eventType)}</IconedText>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Box sx={{display: 'flex', justifyContent: 'center'}} m={1.0}>
                        <IconedText icon={<LocationOnIcon fontSize="large" color="action" />} title={translationContainer.GetTranslation("tables", "location", "Location")}>{location}</IconedText>
                      </Box>
                    </Grid>
                    
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                  
              </Grid>
              <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                
              </Grid>
          </Grid>

        </Box>
    );
  }