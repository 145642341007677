import React, { useState, useEffect } from 'react';
import TranslationText from '../../TranslationText';
import SophConstants from "../../../constants/SophConstants";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import ClearIcon from '@mui/icons-material/Clear';
// import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';


export default function DCheckInListRow(props) {
    const { rowItemData, signupID } = props;
    
    const [rowData, setRowData] = useState(null);
    const [submiting, setSubmitting] = useState(false);


    useEffect(() => {
        if(rowItemData !== undefined && rowItemData != null) {
            setRowData(rowItemData);
        }
    }, [rowItemData]);

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowData !== undefined && rowData !== null && rowData.elementID !== undefined) {
        bgColor = (rowData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const GetLocation = () => {
        let location = '';
        if(rowData.city) {
            location = rowData.city;
        } 

        if(rowData.state) {
            if(location.length > 0) {
                location += ', ';
                location += rowData.state;
            }
        }

        if(rowData.countryID) {
            if(location.length > 0) {
                location += ', ';
                location += rowData.countryID;
            }
        }

        return location;
    }

    const RenderCheckInButtons = () => {
        const [showConfirm, setShowConfirm] = useState(false);

        const HandleFirstCheckAction = () => {
            setShowConfirm(true);
        }

        const HandleConfirmAction = () => {
            setSubmitting(true);

            fetch(SophConstants.API_ADDR + '/api/event/' + signupID + '/checkin/' + rowData.adID + '?api-key=foo', {
                method: 'post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {
    
                if(jsonData && jsonData.checkedIn) {
                    console.log("Checked in");
                    setRowData({...rowData, checkedIn:true});
                }
    
                setSubmitting(false);
    
            }).catch((err) => {
                console.log(err);
                setRowData({...rowData, checkedIn:false});
                setSubmitting(false);
            });

            setShowConfirm(false);
        }

        const CloseConfirmAction = () => {
            setShowConfirm(false);
            setSubmitting(false);
        }

        if(rowData.checkedIn) {
            return (
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', minWidth:'120px'}}>
                    <CheckIcon color="primary" />
                </Box>
            );
        } else {
            return (
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                    {(showConfirm) ? (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                            <LoadingButton variant="contained" color="primary"  loading={submiting}  onClick={HandleConfirmAction} sx={{minWidth:120}}><TranslationText page='tables' dbKey='confirm-question'>Confirm?</TranslationText></LoadingButton>
                            <Button variant="contained" color="error" onClick={CloseConfirmAction} sx={{marginLeft:2, minWidth:120}}><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                        </Box>
                    ) : (
                        <Button variant="contained" color="primary"  onClick={HandleFirstCheckAction} sx={{minWidth:120}}><TranslationText page='tables' dbKey='check-in'>Check In</TranslationText></Button>
                    )}
                </Box>
            );
        }
    }

    if(rowData === null) {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                    <Grid item xs={12} sm={12}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                        Loading...
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={4} sm={3}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowData.fName}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {rowData.lName}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle }}>
                        {GetLocation()}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'flex-end'}}>
                    <Box sx={{  width:'100%', fontSize: fontSizeTitle, display:'flex', justifyContent:'flex-end'}}>
                        <RenderCheckInButtons />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}