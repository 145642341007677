import React, {useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import { DataGrid } from '@mui/x-data-grid';
import TranslationText from '../TranslationText';
import DPeopleList from './DPeopleList/DPeopleList';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function PeopleViewDatagrid(props) {
    const { peopleData } = props;
    const history = useHistory();

    const [viewPersonOpen, setViewPersonOpen] = useState(false);
    const [viewPersonData, setViewPersonData] = useState({id:0, adID:"", fName:"", lName:"", alias:"", email:"", phoneNumber:"", completed:false, countryID:"", state:"", city:"", event:[] });

    const modalBoxStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      bgcolor: 'background.paper',
      border: '2px solid #555',
      boxShadow: 24,
      p: 4,
    };

    const RenderChip = (paramData) => {
        const { rowData } = paramData;

        if(rowData.type === "Not Graduated") {
            return (
                <Chip key={rowData.key} size="small" label={rowData.type} color="error" />
            );
        } 
        
        if(rowData.type === "In Training") {

            return (
                <Chip key={rowData.key} size="small" color="info" label={rowData.type} />
            );
        }

        return (
            <Chip key={rowData.key} size="small" label={rowData.type} />
        )
    }

    const handleRowClick = (personData) => {
      setViewPersonData(personData);
      setViewPersonOpen(true);
    }

    const closeViewPersonForm = () => {
      setViewPersonOpen(false);
    }

    const EventListElement = (paramData) => {
      const { rowCount, event } = paramData;

      let bgColor = "#FFFFFF";

      if(rowCount % 2 == 0) {
        bgColor = "#F5F5F5";
      }

      let completion = "";
      switch(event.completionStatus) {
        case 0:
          completion = "Not Graduated";
          break;
        case 1:
          completion = "In Training";
          break;
        case 2:
          completion = "Completed";
          break;
        case -1:
          completion = "Dropped";
        default:
          completion = "Not Started";
          break;
      }

      if(event.facilitator == true) {
        completion = "Facilitator";
      }

      const OnClickEvent = () => {
        history.push(`/event/view/${event.eventID}`);
      }

      return (
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', backgroundColor:bgColor }} >
          <Box m={1} onClick={OnClickEvent}>
            <Typography variant="p" component="p" align="left">
              {event.eventName}
            </Typography>
          </Box>
          <Box m={1} >
            <Typography variant="p" component="p" align="left">
              {completion}
            </Typography>
          </Box>
        </Box>
      );
    }


    const EventsListRender = (paramData) => {
      const { events } = paramData;

      if(events == undefined || events == null || events.length == 0) {
        return (
          <div>
            <Typography variant="p" component="p" align="left">
              No Events
            </Typography>
          </div>
        );
      }

      let eventList = [];
      let count = 0;
      events.map((event) => {
        eventList.push(<EventListElement key={count} rowCount={count} event={event} />);
        count++;
      });

      return (
          eventList
      );
    }

    const ViewPersonModal = (modalParams) => {
      return (
          <Modal
              open={!modalParams.isDisabled}
              onClose={closeViewPersonForm}
              aria-labelledby="modal-person"
              aria-describedby="modal-person-info"
              sx={{position:'absolute',
              top:'0%',
              left:'0%',
              overflow:'scroll',
              height:'100%',
              display:'block'}}
          >
              <Box sx={modalBoxStyle}>
                <Grid container >
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h5" align="center">
                      {viewPersonData.fName} {viewPersonData.lName}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="p" component="p" align="center">
                      {viewPersonData.email}
                      <IconButton aria-label="delete" size="small" onClick={() => {navigator.clipboard.writeText(viewPersonData.email)}}>
                        <ContentCopyIcon fontSize="inherit"/>
                      </IconButton>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="p" component="p" align="center">
                      {viewPersonData.phoneNumber}
                      <IconButton aria-label="delete" size="small" onClick={() => {navigator.clipboard.writeText(viewPersonData.phoneNumber)}}>
                        <ContentCopyIcon fontSize="inherit"/>
                      </IconButton>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="p" component="p" align="center">
                      {viewPersonData.highestLevel}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Typography variant="h6" component="h6" align="left">
                      <TranslationText page='tables' dbKey='events-title'>Events</TranslationText>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <EventsListRender events={viewPersonData.event} />
                  </Grid>

                  <Grid item xs={12} mt={6}>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center' }} >
                      <Button variant="contained" onClick={closeViewPersonForm}><TranslationText page='tables' dbKey='close'>Close</TranslationText></Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
          </Modal>
      );
    }



    const columns = [
      { field: 'fName', headerName: 'First Name', flex: 1, editable: false },
      { field: 'lName', headerName: 'Last Name', flex: 1, editable: false },
      { field: 'email', headerName: 'Email', flex: 1, editable: false },
      { field: 'chips', headerName: 'Info', flex: 1, editable: false, renderCell: (params) => {
          
        return (
          <Stack direction="row" spacing={1}>
                {params.value.map((chip) => (
                  <RenderChip key={chip.key} rowData={chip} />
                ))}
          </Stack>
        );
      }},
  ];
  
  return (
    <div>
      <div style={{ height: 500, width: '100%' }}>
        <DPeopleList onRowClick={handleRowClick} peopleListData={peopleData} />
        
      </div>
      <div>
        <ViewPersonModal isDisabled={!viewPersonOpen} />
      </div>
    </div>
  );
}

//<DataGrid columns={columns} rows={peopleData} onRowClick={handleRowClick} />