import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DashboardIcon from '@mui/icons-material/Dashboard';

export default function EventTabs(props) {
  const { changeTabCallback } = props;

  const [value, setValue] = React.useState(0);

  const translationContainer = new TranslationContainer();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(changeTabCallback !== undefined)
      changeTabCallback(newValue);
  };

  return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        
      </Tabs>
  );
}

/*
<Tab icon={<DashboardIcon aria-label="View participants" />} label={translationContainer.GetTranslation('tables', 'home', 'Home')} />
        <Tab icon={<CloudDownloadIcon aria-label="View Resources" />} label={translationContainer.GetTranslation('tables', 'resources', 'Resources')} />
        <Tab icon={<ListAltIcon aria-label="View Sessions" />} label={translationContainer.GetTranslation('tables', 'assignments', 'Assignments')} />
        */
