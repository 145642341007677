import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import PeopleGroupManagement from './PeopleGroupManagement';
import EditUserEmail from './EditUserEmail';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
export default function UserViewModal(props) {

    console.log('UserViewModal: ', JSON.stringify(props, null, 2));

    const { user } = props;

    const [userData, setUserData] = useState({});
    const [deleteUserConfirm, setDeleteUserConfirm] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const GetLocationString = (city, state, country) => {   
        let locationString = '';
        if(city) {
            locationString += city;
        }
        if(state) {
            if(locationString.length > 0) {
                locationString += ', ';
            }
            locationString += state;
        }
        if(country) {
            if(locationString.length > 0) {
                locationString += ', ';
            }
            locationString += country;
        }
        return locationString;
    }

    const PrettyDate = (date) => {
        let prettyDate = '';
        if(date) {
            prettyDate = new Date(date).toLocaleDateString();
        }
        return prettyDate;
    }

    const UpdateUserLevel = (newLevel) => {
        console.log('UpdateUserLevel: ', newLevel);

        aldersgateContext.PostToAPI('user/edit', {userdata: {adID:userData.adID, authLevel:newLevel.target.value}}, null, 1).then((response) => {
            console.log(response);
            setUserData({...userData, authLevel: newLevel.target.value});

        }).catch((error) => {
            console.log(error);
        });

    }

    const ChangeEmailAddress = (newEmail) => {
        console.log('ChangeEmailAddress: ', newEmail);

        aldersgateContext.PostToAPI('admin/user/cemail', {adID:userData.adID, email:newEmail}, null, 1).then((response) => {
            console.log(response);
            setUserData({...userData, email: newEmail});

        }).catch((error) => {
            console.log(error);
        });
    }

    

    const DisplayUserLevel = (elementData) => {
        const {levelProps, onChange} = elementData;

        return (
            <FormControl variant="standard">
                <Select
                    value={levelProps}
                    onChange={onChange}
                    displayEmpty
                    size='small'
                    
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={0}>Member</MenuItem>
                    <MenuItem value={1}>Leader</MenuItem>
                    <MenuItem value={2}>Coordinator</MenuItem>
                    <MenuItem value={3}>GET</MenuItem>
                    <MenuItem value={4}>Super Admin</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const handelConfirmDelete = () => {
        console.log('Confirm Delete User: ', userData);

        aldersgateContext.PostToAPI('user/accdelete', {userID:userData.adID, destroy:false}, null, 1).then((response) => {
            console.log(response);
            setUserData({});
        }).catch((error) => {
            console.log(error);
        });
    }


    return (
        <Paper>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2">
                                    Contact Information
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    {userData?.fName} {userData?.lName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    <EditUserEmail elementData={userData?.email} OnElementChange={ChangeEmailAddress} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    {userData?.phoneNumber ? userData?.phoneNumber : 'No Phone Number'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Typography variant="body1" component="p">
                                    {GetLocationString(userData?.city, userData?.state, userData?.country)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2">
                                    User Information
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Auth Level: <DisplayUserLevel levelProps={userData?.authLevel} onChange={UpdateUserLevel} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Last Active: {PrettyDate(userData?.lastLogin)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Account Status: {userData?.accountSuspended ? "Account Suspended" : 'Active'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} mt={2}>
                    <Box>
                        <PeopleGroupManagement user={userData} />
                    </Box>
                </Grid>

            </Grid>

            <Box sx={{position: 'absolute', top: 0, right: 0}}>
            {deleteUserConfirm ? 
                    <Box>
                        Delete Account?
                        <IconButton aria-label="confirm" color="success" onClick={handelConfirmDelete}>
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton aria-label="cancel" color="error" onClick={()=>{setDeleteUserConfirm(false)}}>
                            <CancelIcon />
                        </IconButton>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                : 
                <IconButton aria-label="delete" color="error" onClick={()=>{setDeleteUserConfirm(true)}}>
                    <DeleteIcon />
                </IconButton>
                }
            </Box>
        </Paper>
    )
}