import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import TranslationText from '../../TranslationText';
import TranslationContainer from '../../../localization/TranslationContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DynamicEventListItem from './DynamicEventListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function DynamicEventList(props) {
    const { eventListData, onRowClick } = props;

    
    const [eventList, setEventList] = useState([]);
    const [sortBy, setSortBy] = useState('startingDate');
    const [sortDirection, setSortDirection] = useState('desc');

    const [dropDownState, setDropDownState] = useState(0);

    const [currentPage, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [displayRows, setDisplayRows] = useState([]);

    let fontSizeTitle = 14;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const translationContainer = new TranslationContainer();

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (!smMatches) ? '80%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(() => {

        if(eventListData === undefined || eventListData === null)
            return;

        let sortData = [...eventListData];

        let i = 0;
        let rows = [];
        sortData.sort(handleSortBy);
        sortData.forEach(event => {
            let processedRow = {...event, elementID:i, onRowClick:onRowClick};

            if(event.endingDate !== undefined && event.endingDate !== null && event.endingDate !== "") {
                //if event ending date has passed and there is no report, set status to "Report Missing"
                let endingDate = new Date(event.endingDate);
                let currentDate = new Date();
                if(endingDate < currentDate) {
                    if(event.report === undefined || event.report === null || event.report === "") {
                        processedRow.eventStatus = "Report Missing";
                        //a report is missing, set color to dark orange
                        processedRow.eventStatusColor = "#f59725";
                    }
                }
            }

            //uppercase first letter of eventStatus
            processedRow.eventStatus = processedRow.eventStatus.charAt(0).toUpperCase() + processedRow.eventStatus.slice(1);

            i++;
            rows.push(processedRow);
        });

        setEventList(rows);
    }, [eventListData, sortBy, sortDirection]);

    useEffect(() => {
        let page = currentPage ? currentPage : 0;
        let count = eventList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        let rows = eventList.slice(start, end);
        setDisplayRows(rows);
    }, [eventList, currentPage, rowsPerPage]);

    useEffect(() => {
        if(smMatches) {
            setRowsPerPage(15);
        } else {
            setRowsPerPage(5);
        }

        setPage(0);
    }, [smMatches]);
    

    const handleSortBy = (a, b) => {
        if(sortDirection === 'asc') {
            if(a[sortBy] < b[sortBy]) {
                return -1;
            }
            if(a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        } else {
            if(a[sortBy] < b[sortBy]) {
                return 1;
            }
            if(a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        }
    }


    function ChangeSort (event, data) {
        let { fieldID, direction } = data;
        console.log('ChangeSort: ' + fieldID + ' ' + direction);

        setSortBy(fieldID);
        setSortDirection(direction);
    }

    const RenderSortArrow = (arrowProps) => {
        const { fieldID } = arrowProps;
        if(sortBy === fieldID) {
            if(sortDirection === 'asc') {
                let parameter = { fieldID: fieldID, direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropUpIcon  sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            } else if(sortDirection === 'desc') {
                let parameter = { fieldID: 'updateAt', direction: 'desc' }
                return (
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#000000', textAlign:'left' }}>
                        <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                            <ArrowDropDownIcon sx={{color:'#000000'}}/>
                        </IconButton>
                    </Box>
                )
            }
        } else {
            let parameter = { fieldID: fieldID, direction: 'asc' }
            return (
                <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', color:'#bbbbbb', textAlign:'left' }}>
                    <IconButton onClick={(event) => ChangeSort(event, parameter)}>
                        <ArrowDropDownIcon  sx={{color:'#bbbbbb'}}/>
                    </IconButton>
                </Box>
            )
        }

    }

    const RenderHeader = () => {
        return (
            <Box>
                <Grid container p={1} sx={{backgroundColor:"#ffffff"}}>
                    <Grid item xs={4} sm={4}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                                        <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        <TranslationText page='tables' dbKey='event-name'>Event Name</TranslationText>
                                        </Box>
                                        <RenderSortArrow  fieldID='eventName'/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                        
                    <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                            <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                            <TranslationText page='tables' dbKey='event-type'>Type</TranslationText>
                            </Box>
                            <RenderSortArrow  fieldID='eventType'/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={1} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                        #
                                    </Box>
                                    <RenderSortArrow  fieldID='blabla'/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                    <TranslationText page='tables' dbKey='dates'>Dates</TranslationText>
                                    </Box>
                                    <RenderSortArrow  fieldID='endingDate'/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{width:'100%', height:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                        <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                            <Box>
                                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left' }}>
                                    <TranslationText page='tables' dbKey='event-status'>Status</TranslationText>
                                    </Box>
                                    <RenderSortArrow  fieldID='eventStatus'/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    const handleDropdownChange = (event) => {
        setDropDownState(event.target.value);
        switch(event.target.value) {
            case 0:
                setSortBy('updatedAt');
                setSortDirection('desc');
                break;
            case 1:
                setSortBy('eventName');
                setSortDirection('asc');
                break;
            case 2:
                setSortBy('eventType');
                setSortDirection('asc');
                break;
            default:
                break;
        }
    }

    const RenderFooter = () => {

        let page = currentPage ? currentPage : 0;
        let count = eventList.length;
        let start = page * rowsPerPage;
        let end = start + rowsPerPage;

        if(end > count)
            end = count;
        
        const RenderTools = () => {
            if(displayRows.length <= 0) //if no rows, don't show tools
                return (<Box></Box>);

            return (
                    <Box>
                        {smMatches ? (
                        <Box></Box>
                    ) : (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                            <Box>
                                <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label"><TranslationText page='tables' dbKey='sort-by'>Sort By</TranslationText></InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={dropDownState}
                                        label={translationContainer.GetTranslation('tables', 'sort-by', 'Sort By')}
                                        onChange={handleDropdownChange}
                                    >
                                        <MenuItem value={0}>Date</MenuItem>
                                        <MenuItem value={1}>Event Name</MenuItem>
                                        <MenuItem value={2}>Event type</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
            );
        }

        //footer with pagination on right side
        return (
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', alignContent:'center'}} >
                 
                 {<RenderTools />}

                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center', alignContent:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle, fontWeight:'bold', textAlign:'left', color:'bbbbbb' }}>
                    {start+1}-{end < 1 ? 1 : end} <TranslationText page='tables' dbKey='of'>of</TranslationText> {count < 1 ? 1 : count}
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageBackward}>
                            <KeyboardArrowLeftIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={SetPageForward}>
                            <KeyboardArrowRightIcon  sx={{color:'#bbbbbb'}} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    const SetPageForward = () => {
        setPage( prev => {
            let newPage = currentPage + 1;

            if(newPage * rowsPerPage > eventList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }

    const SetPageBackward = () => {
        setPage( prev => {
            let newPage = currentPage - 1;

            if(newPage * rowsPerPage > eventList.length)
                return prev;
    
            if(newPage < 0)
                return prev;

                return newPage;
            }
        );
    }
    

    return (
        <Box>
            {smMatches ? (<RenderHeader />)
                : (null)
            }
            <Box>
                {(displayRows.length > 0) ?  displayRows.map((rowItemData) => (
                    <DynamicEventListItem key={rowItemData.id} rowData={rowItemData} onRowClick={onRowClick} />
                )) : (<Box><Box pt={3}><TranslationText page='tables' dbKey='no-recent-events'>No Recent Events</TranslationText></Box></Box>)}
            </Box>

            <RenderFooter />
        </Box>
    )
}