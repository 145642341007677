import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function CreateEventForm1(props) {
    const { values, handleChange } = props;

    const translationContainer = new TranslationContainer();

    return (
      <Grid container>
          <Grid item xs={12}>
          <TextField
            placeholder={translationContainer.GetTranslation('tables', 'event-name', 'Event Name')}
            label={translationContainer.GetTranslation('tables', 'event-name', 'Event Name')}
            onChange={handleChange('eventName')}
            defaultValue={values.eventName}
            margin="normal"
            fullWidth
            variant='standard'
          />
          </Grid>
      </Grid>
    )
}
