import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import Editor from 'react-simple-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { parse } from 'date-fns';


export default function AddContentPost(props) {
    const { contentData, closeAddContent } = props;

    const [postData, setPostData] = useState({postTitle:'', postPreview:'', postHeader:0, type:'announcement', category:'resource', fsize:0, link:'', language:'en', priority:50});
    const [changedPost, setChangedPost] = useState({postTitle:'', postPreview:'', postHeader:0, type:'announcement', category:'resource', fsize:0, link:'', language:'en', priority:50});
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [deleteContentConfirm, setDeleteContentConfirm] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);
  
    function onChange(e) {
        setChangedPost(prevState => {
            
            let newState = {...prevState, postPreview: e.target.value};
            return {...newState};
        });
    }

    useEffect(() => {
        if(contentData) {
            let updateObject = {adID:contentData.adID, postTitle:contentData.title, postPreview:contentData.preview, postHeader:contentData.headerType, type:contentData.type, category:contentData.category, fsize:contentData.fsize, link:contentData.link, language:contentData.language, priority:contentData.priority};
            setPostData(updateObject);
            setChangedPost(updateObject);
            setInEditMode(true);
        }
    }, [contentData]);

    useEffect(() => {

        if(changedPost.postTitle !== postData.postTitle || changedPost.postPreview !== postData.postPreview || changedPost.postHeader !== postData.postHeader || changedPost.type !== postData.type || changedPost.category !== postData.category || changedPost.fsize !== postData.fsize || changedPost.link !== postData.link || changedPost.language !== postData.language || changedPost.priority !== postData.priority)
            setSaveBtnEnabled(true);
        else
            setSaveBtnEnabled(false);

    }, [changedPost]);

    const setSnackbarMessage = (message) => {
        
    }

    const handleCancel = () => {
        setChangedPost({...postData});

        if(closeAddContent)
            closeAddContent();
    };


    const handleChange = (event) => {
        const { id, value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, [id]: value};
            return {...newState};
        });
    };

    const handleTypeChange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'type': value};
            return {...newState};
        });
    };

    const handleCategoryChange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'category': value};
            return {...newState};
        });
    };

    const handleLanguageChange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'language': value};
            return {...newState};
        });
    };

    const handleSizeChange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'fsize': parseInt(value) || 0};
            return {...newState};
        });
    };

    const handlePriorityhange = (event) => {
        const { value } = event.target;

        setChangedPost(prevState => {
            let newState = {...prevState, 'priority': parseInt(value) || 0};
            return {...newState};
        });
    };

    const handleEditorChange = (event) => {
        let newText = sanitizeHtml(event.target.value);

        //if newText is the same as the current text, don't update
        if(newText === changedPost.postPreview)
            return; 


        setChangedPost(prevState => {
            let newState = {...prevState, postPreview: newText};
            return {...newState};
        });
    }

    const handleSave = () => {
        let updateData = {};

        updateData.priority = parseInt(changedPost.priority) || 50;
        updateData.title = sanitizeHtml(changedPost.postTitle);
        updateData.preview = sanitizeHtml(changedPost.postPreview);
        updateData.type = changedPost.type;
        updateData.category = changedPost.category;
        updateData.link = changedPost.link === '' ? null : changedPost.link;
        updateData.fsize = parseInt(changedPost.fsize);
        updateData.headerType = 0;
        updateData.headerImageURL = null;
        updateData.language = changedPost.language;

        //console.log("EditEventEmail msg: " + JSON.stringify(msgData, null, 4));

        aldersgateContext.PostToAPI('content/post', updateData, null, 1).then((success, err) => {
            if(success) {
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Content Added!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Content add error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Content add error!");
        });

    };

    const handleUpdateSave = () => {
        let updateData = {};

        updateData.adID = changedPost.adID;
        updateData.priority = changedPost.priority !== postData.priority ? parseInt(changedPost.priority) : undefined;
        updateData.title = changedPost.postTitle !== postData.postTitle ? sanitizeHtml(changedPost.postTitle) : undefined;
        updateData.preview = changedPost.postPreview !== postData.postPreview ? sanitizeHtml(changedPost.postPreview) : undefined;
        updateData.type = changedPost.type !== postData.type ? changedPost.type : undefined;
        updateData.category = changedPost.category !== postData.category ? changedPost.category : undefined;
        updateData.link = changedPost.link !== postData.link ? changedPost.link : undefined;
        updateData.fsize = changedPost.fsize !== postData.fsize ? parseInt(changedPost.fsize) : undefined; 
        updateData.headerType = 0;
        updateData.headerImageURL = null;
        updateData.language = (changedPost.language !== postData.language) ? changedPost.language : undefined;

        console.log("changed language: " + changedPost.language + " postData language: " + postData.language);

        aldersgateContext.PostToAPI('content/upost/' + changedPost.adID, updateData, null, 1).then((success, err) => {
            if(success) {
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Content Updated!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Content update error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Content update error!");
        });

    };

    const handelConfirmDelete = () => {
        aldersgateContext.PostToAPI('content/dpost/' + changedPost.adID, {delete:true}, null, 1).then((success, err) => {
            if(success) {
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Content Deleted!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Content delete error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Content delete error!");
        });
    }

    return (
        <Box>
            {inEditMode ? (
            <Box sx={{position: 'absolute', top: 0, right: 0}}>
            {deleteContentConfirm ? 
                    <Box>
                        Delete Content?
                        <IconButton aria-label="confirm" color="success" onClick={handelConfirmDelete}>
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton aria-label="cancel" color="error" onClick={()=>{setDeleteContentConfirm(false)}}>
                            <CancelIcon />
                        </IconButton>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                : 
                <IconButton aria-label="delete" color="error" onClick={()=>{setDeleteContentConfirm(true)}}>
                    <DeleteIcon />
                </IconButton>
                }
            </Box>
            ) : null}
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="typeLable">Category</InputLabel>
                    <Select
                    labelId="typeLable"
                    id="category"
                    value={changedPost.category}
                    label="Category"
                    onChange={handleCategoryChange}
                    size='small'
                    >
                    <MenuItem value="resource">Resource</MenuItem>
                    <MenuItem value="Module 1">Module 1</MenuItem>
                    <MenuItem value="Module 2">Module 2</MenuItem>
                    <MenuItem value="Journey">Journey</MenuItem>
                    <MenuItem value="Mobilization">Mobilization</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="typeLable">Type</InputLabel>
                    <Select
                    labelId="typeLable"
                    id="type"
                    value={changedPost.type}
                    label="Type"
                    onChange={handleTypeChange}
                    size='small'
                    >
                    <MenuItem value="announcement">Announcement</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="article">Article</MenuItem>
                    <MenuItem value="podcast">Podcast</MenuItem>
                    <MenuItem value="link">Link</MenuItem>
                    <MenuItem value="pdf">PDF</MenuItem>
                    <MenuItem value="doc">DOC</MenuItem>
                    <MenuItem value="ppt">PPT</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="typeLable">Language</InputLabel>
                    <Select
                    labelId="typeLable"
                    id="language"
                    value={changedPost.language}
                    label="Language"
                    onChange={handleLanguageChange}
                    size='small'
                    >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">French</MenuItem>
                    <MenuItem value="pt">Portuguese</MenuItem>
                    <MenuItem value="es">Spanish</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {changedPost.type === 'video' || changedPost.type === 'podcast' ? (
                <Grid item xs={12}>
                    <TextField fullWidth size="small" label="Video Link" id="link" value={changedPost.link} onChange={handleChange}/>
                </Grid>) : null}

            {changedPost.type === 'pdf' || changedPost.type === 'doc' || changedPost.type === 'ppt' || changedPost.type === 'link' ? (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth size="small" label="Asset Link" id="link" value={changedPost.link} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth size="small" label="Asset Size (kb)" id="size" value={changedPost.fsize} onChange={handleSizeChange}/>
                    </Grid>
                </Grid>
            </Grid>) : null}

            <Grid item xs={12} sm={10}>
                <TextField fullWidth size="small" label="Title" id="postTitle" value={changedPost.postTitle} onChange={handleChange}/>
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField fullWidth size="small" label="Post Priority" id="priority" value={changedPost.priority} onChange={handlePriorityhange}/>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ width: '100%', minHeight: 200, borderRadius: '5px', textAlign:'left' }}>
                {changedPost.type == 'announcement' || changedPost.type == 'article' ? <Typography variant="body2" mb={1}>Text Body</Typography> : <Typography variant="body2" mb={1}>Description</Typography>}
                <Editor value={changedPost.postPreview} onChange={onChange} containerProps={{ style: { height:'100%', minHeight: 200, overflow:'scroll' } }}/>
                    
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    {inEditMode ? <Button variant="contained" size="small" onClick={handleUpdateSave} disabled={!saveBtnEnabled} color="success">Update</Button> 
                    : <Button variant="contained" size="small" onClick={handleSave} disabled={!saveBtnEnabled} color="success">Save</Button>}
                    <Button variant="contained" size="small" onClick={handleCancel} color="error">Cancel</Button>
                </Stack>
            </Grid>
        </Grid>
        </Box>
    );
}