import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationText from '../../components/TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';


export default function ViewSession(props) {
    const {facilitators, eventID, closeForm, updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const [editSessionData, setEditSessionData] = useState({eventID:null, name:'', sessionStart:dayjs(new Date()), time:dayjs(new Date()), sessionLength:45, facilitatorID:''});
    const [facilitatorList, setFacilitatorList] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const [value, setValue] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    const translationContainer = new TranslationContainer();

    useEffect(() => {
        setEditSessionData({...props.sessionData, time:dayjs(props.sessionData.sessionStart)});
    }, [props.sessionData]);

    useEffect(() => {
        setFacilitatorList(facilitators);
    }, [facilitators]);

    const handleCloseForm = () => {
        closeForm(true);
    }

    
    const handleSubmit = () => {
      let editedData = {...changedData};
      editedData.id = undefined;
      let sessionID = editSessionData.adID;

      aldersgateContext.PostToAPI('event/' + eventID + '/updatesession/' + sessionID, editedData, null, 1).then((success, err) => {
        if(success) {
            updateData(prevState => {
                let newState = prevState;
                newState = newState+1;
                return {...newState};
            });
        }

        if(err) {
            console.log("Error: " + err);
        }

    }).catch((error) => {
        console.log("Error: " + error);
    });
      
    //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/updatesession/' + sessionID + '?api-key=foo', {
          
    
    }

    const handleDeleteSession = () => {
        let sessionID = editSessionData.adID;

        aldersgateContext.PostToAPI('event/' + eventID + '/deleteSession/' + sessionID, {myid:sessionID}, null, 1).then((success, err) => {
            if(success) {
                updateData(prevState => {
                    let newState = prevState;
                    newState = newState+1;
                    return {...newState};
                });

                handleCloseForm();
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/deleteSession/' + sessionID + '?api-key=foo', {

    }

    const RenderDelete = () => {
        if(deleteConfirm) {
            return (
                <Box sx={{position:'absolute', top:10, right:10 }}>
                    <Box sx={{display:'inline-block', mr:1}}>
                        <Typography variant="body1" color="textSecondary">Are you sure?</Typography>
                    </Box>
                    <Box sx={{display:'inline-block'}}>
                        <Button variant="contained" color="error" onClick={handleDeleteSession}>Yes</Button>
                    </Box>
                    <Box sx={{display:'inline-block', ml:1}}>
                        <Button variant="contained" onClick={() => setDeleteConfirm(false)}>No</Button>
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box sx={{position:'absolute', top:10, right:10 }}>
                    <IconButton aria-label="delete" onClick={() => setDeleteConfirm(true)}>
                        <Delete />
                    </IconButton>
                </Box> 
            );
        }
    }

    const RenderEditSession = ()=> {

        const handleSessionNameChange = (sessionNameData) => {
            setEditSessionData(prev => ({...prev, name:sessionNameData.target.value}));
            setChangedData(prev => ({...prev, name:sessionNameData.target.value}));
            setSaveBtnEnabled(true);
        }
        
        const handleFacilitatorSelect = (facilitatorSelectData) => {
            setEditSessionData(prev => ({...prev, facilitatorID:facilitatorSelectData.target.value}));
            setChangedData(prev => ({...prev, facilitatorID:facilitatorSelectData.target.value}));
            setSaveBtnEnabled(true);
        }

        const handelDateChange = (date) => {
            
            let sessionStart = new Date(date);
            sessionStart.setHours(editSessionData.time.hour());
            sessionStart.setMinutes(editSessionData.time.minute());
            sessionStart.setSeconds(0);

            setEditSessionData(prev => ({...prev, sessionStart:sessionStart}));
            setChangedData(prev => ({...prev, sessionStart:sessionStart}));

            setSaveBtnEnabled(true);
        }

        const handelTimeChange = (newValue) => {
            let sessionStart = new Date(editSessionData.sessionStart);

            sessionStart.setHours(newValue.hour());
            sessionStart.setMinutes(newValue.minute());
            sessionStart.setSeconds(0);

            setEditSessionData(prev => ({...prev, time:newValue}));
            setChangedData(prev => ({...prev, sessionStart:sessionStart}));
            setSaveBtnEnabled(true);
        }

        const handelLengthChange = (lengthValue) => {
            setEditSessionData(prev => ({...prev, sessionLength:lengthValue.target.value}));
            setChangedData(prev => ({...prev, sessionLength:lengthValue.target.value}));
            setSaveBtnEnabled(true);
        }

        const handleAddSessionSubmit = () => {

            if(editSessionData?.eventID === null || editSessionData?.name === '') {
                return;
            }

            //adjust the date to the time
            let sessionStart = new Date(editSessionData.sessionStart);
            sessionStart.setHours(editSessionData.time.hour());
            sessionStart.setMinutes(editSessionData.time.minute());
            sessionStart.setSeconds(0);

            let sessionSubmitData = {...editSessionData, sessionStart:sessionStart};

            aldersgateContext.PostToAPI('event/' + editSessionData.eventID + '/session/' + '?api-key=foo', sessionSubmitData, null, 1).then((success, err) => {
                if(success) {
                    
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });
            
            //fetch(SophConstants.API_ADDR + '/api/event/' + editSessionData.eventID + '/session/' + '?api-key=foo', {
        }

        return (
            <Box>
                <Grid container mt={4} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5" component="h5" gutterBottom>
                        <TranslationText page='tables' dbKey='edit-session'>Edit Session</TranslationText>
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth><TextField id="sessionName" label={translationContainer.GetTranslation("tables", "session-name", "Session Name")} variant="outlined" value={editSessionData?.name} onChange={handleSessionNameChange} /></FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="facilitator-select"><TranslationText page='tables' dbKey='session-facilitator'>Session Facilitator</TranslationText></InputLabel>
                            <Select
                            labelId="facilitator-select"
                            id="simple-facilitator-select"
                            value={editSessionData?.facilitatorID}
                            label={translationContainer.GetTranslation("tables", "session-facilitator", "Session Facilitator")}
                            onChange={handleFacilitatorSelect}
                            >

                            {facilitatorList.map((facilitator) => {
                                return <MenuItem key={facilitator.id} value={facilitator.adID}>{facilitator.fName + ' ' + facilitator.lName}</MenuItem>
                            })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                id="sessionDate"
                                label={translationContainer.GetTranslation("tables", "session-date", "Session Date")}
                                inputFormat="MM/dd/yyyy"
                                value={editSessionData?.sessionStart}
                                onChange={handelDateChange}
                                renderInput={(params) => <TextField  sx={{  width: '100%' }} {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker id="date-picker-input-id" label="Start Time" value={editSessionData.time} onChange={handelTimeChange} renderInput={(props) => <TextField  {...props} helperText={null} sx={{width:'100%'}} />}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="length-select"><TranslationText page='tables' dbKey='session-length'>Session Length</TranslationText></InputLabel>
                            <Select
                            labelId="length-select"
                            id="simple-length-select"
                            value={editSessionData.sessionLength}
                            label={translationContainer.GetTranslation("tables", "session-length", "Session Length")}
                            onChange={handelLengthChange}
                            >
                            <MenuItem value={10}>10 Minutes</MenuItem>
                            <MenuItem value={15}>15 Minutes</MenuItem>
                            <MenuItem value={20}>20 Minutes</MenuItem>
                            <MenuItem value={25}>25 Minutes</MenuItem>
                            <MenuItem value={30}>30 Minutes</MenuItem>
                            <MenuItem value={45}>45 Minutes</MenuItem>
                            <MenuItem value={60}>60 Minutes</MenuItem>
                            <MenuItem value={75}>75 Minutes</MenuItem>
                            <MenuItem value={90}>90 Minutes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box>
            <RenderDelete />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RenderEditSession />
                </Grid>

                <Grid item xs={12} mt={4}>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                        <Button variant="contained" onClick={handleCloseForm} color="error"><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                        </Grid>
                        
                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleSubmit} disabled={!saveBtnEnabled}><TranslationText page='tables' dbKey='save'>Save</TranslationText></Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>

      );
}

