import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TranslationText from '../../TranslationText';
import SophConstants from "../../../constants/SophConstants";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea, CardActions } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';


export default function DCheckInListCard(props) {
    const { rowItemData, signupID } = props;

    const [rowData, setRowData] = useState(rowItemData);
    const [value, setValue] = useState(0);
    const [buttonEnabled, setButtonEnabled ] = useState(true);
    const [submiting, setSubmitting] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setButtonEnabled(rowItemData.createSFButtonEnabled);
    }, [rowItemData]);


    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    const GetLocation = () => {
        let location = '';
        if(rowItemData.city) {
            location = rowItemData.city;
        } 

        if(rowItemData.state) {
            if(location.length > 0) {
                location += ', ';
                location += rowItemData.state;
            }
        }

        if(rowItemData.countryID) {
            if(location.length > 0) {
                location += ', ';
                location += rowItemData.countryID;
            }
        }

        return location;
    }

    const RenderCheckInButtons = () => {
        const [showConfirm, setShowConfirm] = useState(false);

        const HandleFirstCheckAction = () => {
            setShowConfirm(true);
        }

        const HandleConfirmAction = () => {
            setSubmitting(true);

            fetch(SophConstants.API_ADDR + '/api/event/' + signupID + '/checkin/' + rowData.adID + '?api-key=foo', {
                method: 'post',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {
    
                if(jsonData && jsonData.checkedIn) {
                    console.log("Checked in");
                    setRowData({...rowData, checkedIn:true});
                }
    
                setSubmitting(false);
    
            }).catch((err) => {
                console.log(err);
                setRowData({...rowData, checkedIn:false});
                setSubmitting(false);
            });

            setShowConfirm(false);
        }

        const CloseConfirmAction = () => {
            setShowConfirm(false);
            setSubmitting(false);
        }

        if(rowData.checkedIn) {
            return (
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', minWidth:'120px'}}>
                    <CheckIcon color="primary" />
                </Box>
            );
        } else {
            return (
                <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                    {(showConfirm) ? (
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%'}}>
                            <LoadingButton variant="contained" color="primary"  loading={submiting}  onClick={HandleConfirmAction} sx={{minWidth:120}}><TranslationText page='tables' dbKey='confirm-question'>Confirm?</TranslationText></LoadingButton>
                            <Button variant="contained" color="error" onClick={CloseConfirmAction} sx={{marginLeft:2, minWidth:120}}><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                        </Box>
                    ) : (
                        <Button variant="contained" color="primary"  onClick={HandleFirstCheckAction} sx={{minWidth:120}}><TranslationText page='tables' dbKey='check-in'>Check In</TranslationText></Button>
                    )}
                </Box>
            );
        }
    }

    return (
        <Paper sx={{ border: 0, boxShadow: 0, backgroundColor:bgColor, ':hover': {backgroundColor:'#E0E0E0'}}}>
            <Box p={1}>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start', gap:'10px'}}>
                        <Box pt={1}>
                            
                        </Box>
                        <Box sx={{width:'100%'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                        <Box>
                                            <Typography color="text.primary" sx={{ fontSize: 16, textAlign:'left'}}>
                                                {rowItemData.lName}, {rowItemData.fName}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {rowItemData.email}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="text.secondary" sx={{ fontSize: 12, textAlign:'left'}}>
                                                {GetLocation()}
                                            </Typography>
                                        </Box>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                    <Box>
                                        {RenderCheckInButtons()}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
        </Paper>
    );
}