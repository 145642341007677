import React from 'react';
import FormControl from '@mui/material/FormControl';
import TranslationText from '../TranslationText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';

export default function CreateEventForm2(props){

    const { values, handleChange } = props;

    return (
      <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="eventType"><TranslationText page='tables' dbKey='event-type'>Event Type</TranslationText></InputLabel>
            <Select
              labelId="eventType"
              id="eventType"
              value={values.eventType}
              onChange={handleChange('eventType')}
              fullWidth
              variant='standard'
            >
              <MenuItem value={'Module 1'}><TranslationText page='tables' dbKey='hm1'>Module 1</TranslationText></MenuItem>
              <MenuItem value={'Module 2'}><TranslationText page='tables' dbKey='hm2'>Module 2</TranslationText></MenuItem>
              <MenuItem value={'Journey'}><TranslationText page='tables' dbKey='hmj'>History Makers Journey</TranslationText></MenuItem>
              <MenuItem value={'Mobilization'}><TranslationText page='tables' dbKey='mobilization'>Flight School</TranslationText></MenuItem>
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <InputLabel id="eventMedium"><TranslationText page='tables' dbKey='training-method'>Training Method</TranslationText></InputLabel>
            <Select
              labelId="eventMedium"
              id="eventMedium"
              value={values.eventMedium}
              onChange={handleChange('eventMedium')}
              fullWidth
              variant='standard'
            >
              <MenuItem value={'In-Person'}><TranslationText page='tables' dbKey='in-person'>In-Person</TranslationText></MenuItem>
              <MenuItem value={'Hybrid'}><TranslationText page='tables' dbKey='hybrid'>Hybrid</TranslationText></MenuItem>
              <MenuItem value={'Online'}><TranslationText page='tables' dbKey='online'>Online</TranslationText></MenuItem>
            </Select>
          </FormControl>
          </Grid>
        </Grid>
    );
}