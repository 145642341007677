import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import TranslationText from '../TranslationText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { es, enUS, ptBR, fr, ru } from 'date-fns/locale';

export default function EventFilterOptions(props) {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const { setFilterData, search } = props;
    const [startDate, setStartDate] = React.useState(firstDay);
    const [endDate, setEndDate] = React.useState(lastDay);
    const [eventType, setEventType] = React.useState('All');

    const translationContainer = new TranslationContainer();

    let locale = 'en';

    switch(translationContainer.GetLanguage()) {
        case 'es':
            locale = es;
            break;
        case 'en':
            locale = enUS;
            break;
        case 'pt':
            locale = ptBR;
            break;
        case 'fr':
            locale = fr;
            break;
        case 'ru':
            locale = ru;
            break;
        default:
            locale = enUS;
            break;
    }

    const dateBtnLocals = {
      okButtonLabel: translationContainer.GetTranslation('tables', 'confirm', 'Confirm'),
      cancelButtonLabel: translationContainer.GetTranslation('tables', 'cancel', 'Cancel')
    };

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
                <TextField id="name-filter" label={translationContainer.GetTranslation('tables', 'event-name', 'Event Name')} variant="outlined" size="small"
                    onChange={(newValue) =>{
                            console.log(newValue.target.value);
                            setFilterData(prevState => {
                                return {...prevState, ['name']: newValue.target.value};
                            });
                        }
                    }
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="event-type-filter"><TranslationText page='tables' dbKey='event-type'>Event Type</TranslationText></InputLabel>
                <Select
                labelId="event-type-filter"
                id="event-type-filter-select"
                value={eventType}
                label={translationContainer.GetTranslation('tables', 'event-type', 'Event Type')}
                size="small"
                onChange={(newValue) =>{
                        setFilterData(prevState => {
                            return {...prevState, ['eventType']: newValue.target.value};
                        });
                        setEventType(newValue.target.value);
                    }
                }
                >
                <MenuItem value="All">
                    <em>{translationContainer.GetTranslation('tables', 'all', 'All')}</em>
                </MenuItem>
                <MenuItem value="Module 1">{translationContainer.GetTranslation('tables', 'hm1', 'Module 1')}</MenuItem>
                <MenuItem value="Module 2">{translationContainer.GetTranslation('tables', 'hm2', 'Module 2')}</MenuItem>
                <MenuItem value="Journey">{translationContainer.GetTranslation('tables', 'hmj', 'HMJ')}</MenuItem>
                <MenuItem value="Flight">{translationContainer.GetTranslation('tables', 'mobilization', 'Flight School')}</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100, height:10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={dateBtnLocals}>
                    <MobileDatePicker
                    id="start-date-filter-select"
                    label={translationContainer.GetTranslation('tables', 'starting-date', 'Starting Date')}
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                        setFilterData(prevState => {
                            return {...prevState, ['startDate']: newValue};
                        });
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 100 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={dateBtnLocals}>
                <MobileDatePicker
                id="end-date-filter-select"
                label={translationContainer.GetTranslation('tables', 'ending-date', 'Ending Date')}
                size="small"
                value={endDate}
                onChange={(newValue) => {
                    setFilterData(prevState => {
                        return {...prevState, ['endDate']: newValue};
                    });
                    setEndDate(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="outlined" size="large" onClick={search}><TranslationText page='tables' dbKey='search'>Search</TranslationText></Button>
            </FormControl>
    </div>
    )
}