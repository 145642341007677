import React, {useEffect, useState, useContext } from "react";
import LanguageContext from '../context/LanguageContext';


export default function TranslationText(props) {
    const {page, dbKey, children} = props;

    //get context
    const {language, translationContainer} = useContext(LanguageContext);

    if(language === 'en'){
        return (
            <>
                {children}
            </>
        )
    } else {

        let translation = translationContainer.GetTranslation(page, dbKey, children);

        return (
            <>
                {translation}
            </>
        )
    }
}
