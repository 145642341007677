import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';

export default function PeopleFilterOptions(props) {
    const { setFilterData, search } = props;
    const [peopleType, setPeopleType] = React.useState('All');

    const translationContainer = new TranslationContainer();

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 240 }}>
                <TextField id="name-filter" label={translationContainer.GetTranslation('tables', 'name', 'Name')} variant="outlined" size="small"
                    onChange={(newValue) =>{
                            console.log(newValue.target.value);
                            setFilterData(prevState => {
                                return {...prevState, ['name']: newValue.target.value};
                            });
                        }
                    }
                />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="people-type-filter">Training Level</InputLabel>
                <Select
                labelId="people-type-filter"
                id="people-type-filter-select"
                value={peopleType}
                label={translationContainer.GetTranslation('tables', 'training level', 'Training Level')}
                size="small"
                onChange={(newValue) =>{
                        setFilterData(prevState => {
                            return {...prevState, ['peopleLevel']: newValue.target.value};
                        });
                        setPeopleType(newValue.target.value);
                    }
                }
                >
                <MenuItem value="All">
                    <em>{translationContainer.GetTranslation('tables', 'all', 'All')}</em>
                </MenuItem>
                <MenuItem value="Module 1">{translationContainer.GetTranslation('tables', 'hm1', 'Module 1')}</MenuItem>
                <MenuItem value="Journey">{translationContainer.GetTranslation('tables', 'hmj', 'HMJ')}</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button variant="outlined" size="large" onClick={search}>{translationContainer.GetTranslation('tables', 'search', 'Search')}</Button>
            </FormControl>
    </div>
    )
}