import React, {useEffect, useState, useContext} from  'react';
import AuthContext from '../../context/AuthContext';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationText from '../../components/TranslationText';
//import useToken from '../auth/useToken'
import CreateEventForm1 from './CreateEventForm1';
import CreateEventForm2 from './CreateEventForm2';
import CreateEventForm3 from './CreateEventForm3';
import CreateEventConfirm from './CreateEventConfirm';
import CreateEventComplete from './CreateEventComplete';
import Title from '../Title';
import TranslationContainer from '../../localization/TranslationContainer';

// import Confirm from './Confirm';
// import Success from './Success';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import EditIcon from '@mui/icons-material/Edit';
import StreetviewIcon from '@mui/icons-material/Streetview';
import EventIcon from '@mui/icons-material/Event';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

export default function NewEventProcess(props) {
    //const { token } = useToken();

    var endingDate = new Date(); // Now
    endingDate.setDate(endingDate.getDate() + 30);

    let authContext = useContext(AuthContext);
    let aldersgateContext = useContext(AldersgateContext);

    let translationContainer = new TranslationContainer();

    const [eventData, setEventData] = React.useState({
        eventName: '',
        eventType:  'Module 1',
        eventMedium:  'In-Person',
        countryID:  '',
        state: '',
        city: '',
        startingDate:  new Date(),
        endingDate:  endingDate,
        userID: authContext.adID,
        signupform: {bannerID:0}
    });

    const [step, setStep] = React.useState({currentStep: 0});

    useEffect(() => {
        aldersgateContext.GetFromAPI('user/getinfo/userlocation', null, 1).then((success, err) => {

            if(success) {
                if(success) {
                    setEventData({...eventData, countryID: success.country, city: success.city, state: success.state});
                } else {
                    console.log("Error: " + success.message);
                }
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
            
    }, []);

    // Proceed to next step
    const nextStep = () => {
        setStep({currentStep: step.currentStep + 1});
    };

    // Go back to prev step
    const prevStep = () => {
        setStep({currentStep: step.currentStep - 1});
    };

    const handleReset = () => {
        setStep({currentStep: step.currentStep + 0});
    };

    // Handle fields change
    const handleChange = input => e => {
        setEventData({ ...eventData, [input]: e.target.value });
    };

    const handleDateChange = (input) => e => {
        setEventData({ ...eventData, [input]: e });
    };

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
        code = values.code;

        setEventData({ ...eventData, countryID: code });

    };

    const SetEventName = (name) => {
        setEventData({ ...eventData, eventName: name });
    };


    const getSteps= () => {
        return [translationContainer.GetTranslation('tables', 'event-name', 'Event Name'), translationContainer.GetTranslation('tables', 'event-type', 'Event Type'), translationContainer.GetTranslation('tables', 'event-details', 'Event Details'), translationContainer.GetTranslation('tables', 'confirm', 'Confirm')];
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
          case 0:
            return <><EditIcon fontSize="large" /><br /><TranslationText page='tables' dbKey='ec-chose-name'>Choose a name to identify your event</TranslationText></>;
          case 1:
            return <><StreetviewIcon fontSize="large" /><br /><TranslationText page='tables' dbKey='ec-select-etype'>Select the type of event you are planning</TranslationText></>;
          case 2:
              return <><EventIcon fontSize="large" /><br /><TranslationText page='tables' dbKey='ec-elocation'>Where and when will your event take place?</TranslationText></>;
          case 3:
              return <><PlaylistAddCheckIcon fontSize="large" /><br /><TranslationText page='tables' dbKey='ec-confirm'>Does everything look okay?</TranslationText></>;
          default:
            return 'Submitting...';
        }
      }

      const renderSwitch = (currentStep, values) => {
        switch (currentStep) {
            case 0:
                return (
                <CreateEventForm1
                  handleChange={handleChange}
                  values={values}
                />
                );
            case 1:
                return (
                <CreateEventForm2
                  handleChange={handleChange}
                  values={values}
                />
                );
              case 2:
                  return (
                  <CreateEventForm3
                    handleChange={handleChange}
                    handelDateChange = {handleDateChange}
                    handleCountryChange = {handleCountryChange}
                    values={values}
                  />
                  );
              case 3:
                  return (
                  <CreateEventConfirm
                    values={values}
                    setEventName={SetEventName}
                  />
                  );
              case 4:
                  return (
                      <CreateEventComplete values={values} />
                  );
            default:
                return (<div>Unknown step</div>);
        }
    }


    const steps = getSteps();
    const {currentStep} = step;

    return (
        
        <React.Fragment>            
            <Grid container >
                <Grid item xs={12}>
                    <Title><TranslationText page='tables' dbKey='create-new-event'>Create a new event</TranslationText></Title>
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Grid>

                <Grid item xs={12}>
                  <Box m={2} pt={3} pb={3}>
                    <Typography color="textSecondary" >{getStepContent(currentStep)}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {renderSwitch(currentStep, eventData)}
                </Grid>

                <Grid item xs={12}> 
                    <Grid container>
                        <Grid item xs={12}>

                        
                            <Box pt={10}>
                                    {currentStep === steps.length ? (
                                        <div>
                                        <Typography>All steps completed</Typography>
                                        <Button onClick={handleReset}>Reset</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                            disabled={currentStep === 0}
                                            onClick={prevStep}
                                            >
                                            <TranslationText page='tables' dbKey='back'>Back</TranslationText>
                                            </Button>
                                            <Button variant="contained" disabled={eventData?.eventName == ''} color="primary" onClick={nextStep}>
                                            {currentStep === steps.length - 1 ? translationContainer.GetTranslation('tables', 'finish', 'Finish') : translationContainer.GetTranslation('tables', 'next', 'Next')}
                                            </Button>
                                        </div>
                                    )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>

        
        );
    
    
}