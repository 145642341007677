import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function GroupDisplay(props) {
    const {groupData, setGroupData, deleteGroupMember} = props;

    const [group, setGroup] = useState({});
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    useEffect(() => {
        setGroup(groupData);
    }, [groupData]);

    const GroupRoleSelection = (elementData) => {
        const {groupProps, onChange} = elementData;

        return (
            <FormControl variant="standard">
                <Select
                    value={groupProps}
                    onChange={onChange}
                    displayEmpty
                    
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={3}>Member</MenuItem>
                    <MenuItem value={4}>Coordinator</MenuItem>
                    <MenuItem value={5}>Admin</MenuItem>
                </Select>
            </FormControl>
        );
    }

    const handleChange = (event) => {
        const groupRole = event.target.value;
        console.log(groupRole);

        let changedGroupData = {...group, groupmember: {...group.groupmember, groupRole: groupRole}};
        setGroup({...group, groupmember: {...group.groupmember, groupRole: groupRole}});

        setGroupData({changedGroup: changedGroupData});
    };

    const handleDeleteIntent = () => {
        console.log('Delete Group: ', group);
        setShowConfirmDelete(true);
    }

    const handleCancelDelete = () => {
        console.log('Cancel Delete Group: ', group);
        setShowConfirmDelete(false);
    }

    const handelConfirmDelete = () => {
        console.log('Confirm Delete from Group: ', group);
        setShowConfirmDelete(false);

        deleteGroupMember(group.groupmember.adID);
    }

    const handlePrimary = () => {
        console.log('Primary Group: ', group);

        if(!group.groupmember.isDefault) {
            let changedData = {...group };

            changedData.groupmember.isDefault = true;

            setGroupData({changedGroup: changedData});
        }
    }
    
    return (
        <Paper elevation={2}>
            <Box sx={{position:'relative', display:'flex', flexDirection:'row'}} m={2} p={1}>
                <Box>
                    <Typography variant="body1">
                        {group?.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        {group?.groupmember?.groupStatus}
                    </Typography>
                </Box>
                <Box sx={{position: 'absolute', top: 0, right: 0}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                        <GroupRoleSelection groupProps={group?.groupmember?.groupRole} onChange={handleChange} />
                        {showConfirmDelete ? 
                            <Box>
                                <IconButton aria-label="confirm" color="success" onClick={handelConfirmDelete}>
                                    <CheckCircleIcon />
                                </IconButton>
                                <IconButton aria-label="cancel" color="error" onClick={handleCancelDelete}>
                                    <CancelIcon />
                                </IconButton>
                                <IconButton aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        : 
                        <IconButton aria-label="delete" color="error" onClick={handleDeleteIntent}>
                            <DeleteIcon />
                        </IconButton>
                        }
                        <IconButton aria-label="primary" color="disabled" onClick={handlePrimary}>
                            {group?.groupmember?.isDefault ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}