import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TranslationContainer from '../../../localization/TranslationContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';

function GetMediumIcon(medium) {
    switch (medium) {
      case "Online":
        return <TapAndPlayIcon />;
        case "In-Person":
          return <GroupIcon />;
          case "Hybrid":
            return <StreetviewIcon />;
      default:
        break;
    }
  }

export default function DynamicEventListRow(props) {
    const { rowItemData, onRowClick } = props;

    const history = useHistory();

    const options = {month: 'numeric', day: 'numeric' }
    let bgColor = '#FFFFFF';
    
    const translationContainer = new TranslationContainer();

    if(rowItemData !== undefined && rowItemData.elementID !== undefined) {
        bgColor = (rowItemData.elementID % 2 === 0) ? '#F5F5F5' : '#FFFFFF';
    }

    let fontSizeTitle = 14;
    let fontSizeSubTitle = 12;

    const viewEventDetails = (event) => {
        let eventID = event;
        history.push("/event/view/" + eventID);
    }

    function GetEventTypeText(trainingType) {
        switch (trainingType) {
            case "Module 1":
                return translationContainer.GetTranslation("tables", "hm1", "Module 1");
            case "Module 2":
                return translationContainer.GetTranslation("tables", "hm2", "Module 2");
            case "Journey":
                return translationContainer.GetTranslation("tables", "hmj", "Journey");
            case "Mobilization":
                return translationContainer.GetTranslation("tables", "mobilization", "Flight School");
            default:
            break;
        }
    }

    function GetEventStatus(eventStatus) {
        switch (eventStatus) {
            case "In Progress":
                return translationContainer.GetTranslation("tables", "active", "In Progress");
            case "Completed":
                return translationContainer.GetTranslation("tables", "event-completed", "Completed");
            case "Report Missing":
                return translationContainer.GetTranslation("tables", "report-missing", "Report Missing");
            case "Postponed":
                return translationContainer.GetTranslation("tables", "postponed", "Postponed");
            default:
            break;
        }
    }

            
    return (
        <Box onClick={() => rowItemData.onRowClick(rowItemData.id)}>
            <Grid container p={1} sx={{backgroundColor:bgColor,':hover': {backgroundColor:'#E0E0E0'}}} >
                <Grid item xs={4} sm={4}>
                    <Box sx={{height:'100%', display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'flex-start', alignItems:'center', gap:'10px'}}>
                        <Box>
                            {GetMediumIcon(rowItemData.eventMedium)}
                        </Box>
                        <Box>
                            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Box sx={{  fontSize: fontSizeTitle, textAlign:'left' }}>
                                    {rowItemData.eventName}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                     
                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{ fontSize: fontSizeTitle}}>
                        {GetEventTypeText(rowItemData.eventType)}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={1} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                        {rowItemData.participants ? rowItemData.participants.length : 0}
                    </Box>
                </Grid>

                <Grid item xs={4} sm={2} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle }}>
                    {translationContainer.GetLocalizedDateRange(rowItemData.startingDate, rowItemData.endingDate)}
                    </Box>
                </Grid>


                <Grid item xs={4} sm={3} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                    <Box sx={{  fontSize: fontSizeTitle,  color:rowItemData.eventStatusColor }}>
                        {GetEventStatus(rowItemData.eventStatus)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}