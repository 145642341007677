import React, {useState, useEffect} from 'react';
import SophConstants from '../../constants/SophConstants';
import CenteredLoading from "../CenteredLoading";
import Typography from '@mui/material/Typography';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import Link from '@mui/material/Link';

export default function EventNameCard(props) {
    const { eventData } = props;

    const [signupLink, setSignupLink] = useState('');
    const translationContainer = new TranslationContainer();

    const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };

    useEffect(() => {
        let lang = translationContainer.GetLanguage();
        if(lang)
            lang = '/' + lang;
        
        setSignupLink(SophConstants.PUBLIC_ADDR + lang + '/reg/' + eventData.signupID);
    }, [eventData]);


    const ReportReminder = () => {
        if(eventData.endingDate !== undefined && eventData.endingDate !== null && eventData.endingDate !== "") {
            //if event ending date has passed and there is no report, set status to "Report Missing"
            let endingDate = new Date(eventData.endingDate);
            let currentDate = new Date();
            if(endingDate < currentDate) {
                if(eventData.report === undefined || eventData.report === null || eventData.report === "") {
                    return (
                        <Typography component="h2" variant="h6" color="#f59725"><TranslationText page='tables' dbKey='pls-report-banner'>Please Complete the Report for This Event</TranslationText></Typography>
                    );
                }
            }
        }

        return null;
    }

    return (
        <div>
            {(!props.isLoaded) ? (
                    <CenteredLoading />
                  ) : (
                    <div>
                        <Typography component="h2" variant="h4" color="textSecondary">{props.eventData.eventName}</Typography>
                        <Typography component="h2" variant="h6" color="textSecondary">{translationContainer.GetLocalizedDateRange(props?.eventData?.startingDate, props?.eventData?.endingDate, dateOptions)}</Typography>
                        <Link href={signupLink}>{signupLink}</Link>
                        <ReportReminder />

                    </div>
                  )}
        </div>
    );
}