import React from 'react';
import TranslationContainer from '../../../localization/TranslationContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FeedIcon from '@mui/icons-material/Feed';
import ContactsIcon from '@mui/icons-material/Contacts';
import MessageIcon from '@mui/icons-material/Message';

export default function EditTabIcons(props) {

  const { changeTabCallback } = props;

  const [value, setValue] = React.useState(0);

  const translationContainer = new TranslationContainer();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(changeTabCallback !== undefined)
      changeTabCallback(newValue);
  };

  return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<FeedIcon />} label={translationContainer.GetTranslation('tables', 'event-details', 'Event Details')} />
        <Tab icon={<ContactsIcon />} label={translationContainer.GetTranslation('tables', 'sign-up-form', 'Sign Up Form')} />
        <Tab icon={<MessageIcon />} label={translationContainer.GetTranslation('tables', 'communications', 'Communications')} />
      </Tabs>
  );
}
