import React, {useEffect, useState} from  'react';
import EventFeed from '../pEventView/EventFeed';
import EventNameCard from './EventNameCard';
import EventInfoContainer from './EventInfoContainer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export default function EventHomeView(props) {
    const { eventData, isLoading, updateInformation } = props;

    let signupID = eventData.signupID;



    return (
        <Box>
            <Grid container spacin={2}>
                <Grid item xs={12}>
                    <EventNameCard isLoaded={!isLoading} eventData={eventData}/>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <EventInfoContainer eventData={eventData}/>
                </Grid>
                <Grid item xs={12}>
                    <EventFeed signupID={signupID} eventData={eventData} isAdmin={true}/>
                </Grid>
            </Grid>
        </Box>
    );
}