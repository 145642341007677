import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import TranslationContainer from '../../localization/TranslationContainer';
import Grid from '@mui/material/Grid';
import CountrySelect from '../CountrySelect';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { es, enUS, ptBR, fr, ru } from 'date-fns/locale';

export default function CreateEventForm3(props) {

    const { values, handleChange, handelDateChange, handleCountryChange } = props;
    const translationContainer = new TranslationContainer();

    let locale = 'en';

    switch(translationContainer.GetLanguage()) {
        case 'es':
            locale = es;
            break;
        case 'en':
            locale = enUS;
            break;
        case 'pt':
            locale = ptBR;
            break;
        case 'fr':
            locale = fr;
            break;
        case 'ru':
            locale = ru;
            break;
        default:
            locale = enUS;
            break;
    }

    const dateBtnLocals = {
      okButtonLabel: translationContainer.GetTranslation('tables', 'confirm', 'Confirm'),
      cancelButtonLabel: translationContainer.GetTranslation('tables', 'cancel', 'Cancel')
    };
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CountrySelect handleCountryChange={handleCountryChange} currentCountry={values.countryID} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <TextField
              placeholder="State"
              label={translationContainer.GetTranslation('tables', 'state-provence', 'State/Provence')}
              onChange={handleChange('state')}
              defaultValue={values.state}
              margin="normal"
              fullWidth
              variant='standard'
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <TextField
              placeholder="City"
              label={translationContainer.GetTranslation('tables', 'city', 'City')}
              onChange={handleChange('city')}
              defaultValue={values.city}
              margin="normal"
              fullWidth
              variant='standard'
            />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={dateBtnLocals}>
                <MobileDatePicker
                    id="startingDate"
                    label={translationContainer.GetTranslation('tables', 'starting-date', 'Starting Date')}
                    //inputFormat="MM/dd/yyyy"
                    value={values.startingDate}
                    onChange={handelDateChange('startingDate')}
                    renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                />
            </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={dateBtnLocals}>
                <MobileDatePicker
                    id="endingDate"
                    label={translationContainer.GetTranslation('tables', 'ending-date', 'Ending Date')}
                    //inputFormat="MM/dd/yyyy"
                    value={values.endingDate}
                  onChange={handelDateChange('endingDate')}
                    renderInput={(params) => <TextField variant="standard" sx={{  width: '100%' }} {...params} />}
                />
            </LocalizationProvider>
            </Grid>

            </Grid>
    );
}