import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function ManageTabs(props) {
  const { changeTabCallback } = props;

  const [value, setValue] = React.useState(0);
  const translationContainer = new TranslationContainer();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(changeTabCallback !== undefined)
      changeTabCallback(newValue);
  };

  return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="tabs for managing event"
      >
        <Tab icon={<AssignmentIndIcon aria-label="View participants" />} label={translationContainer.GetTranslation('tables', 'check-in', 'Check In')} />
        <Tab icon={<SupervisedUserCircleIcon aria-label="View Facilitators" />} label={translationContainer.GetTranslation('tables', 'report', 'Report')} />
        <Tab icon={<ListAltIcon aria-label="Certificates" />} label={translationContainer.GetTranslation('tables', 'certificates', 'Certificates')} />
      </Tabs>
  );
}
