import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationContainer from '../../localization/TranslationContainer';
import TranslationText from '../TranslationText';
import RegLangDropdown from './RegLangDropdown';
import TOSView from '../legal/TOSView';
import PrivacyView from '../legal/PrivacyView';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RegFieldTextInput from './RegFieldTextInput';
import RegFieldCountrySelect from './RegFieldCountrySelect';
import RegFieldDropdown from './RegFieldDropdown';
import RegSuccess from './RegSuccess';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';

  const headersection = {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingTop:"60"
  }


function ParseFormData(formData) {
    let sections = {...formData.sectionData, type:formData.type};

    sections.groups.map((groupRow, groupIndex) => {
        groupRow.fields = [];
    });

    formData.fields.map((row, index) => {
        sections.groups.map((groupRow, groupIndex) => {
            if(row.group == groupRow.groupID) {
                groupRow.fields.push(row);
            }
        });
    });

    return sections;

}

function SectionTitle(props) {

    return (
        <Box sx={{height:10, alignItems:"flex-end", marginTop:props.marginTop}}>
            <Box sx={headersection}>
                <Box>{<GetSectionIcon icon={props.icon}/>}</Box>
                <Box sx={{paddingLeft:2}}><Typography component="h2" variant="h6" color="textSecondary" align="left">
                        {props.title}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

function GetSectionIcon(props) {
    switch(props.icon) {
        case "AccountBox":
            return (<AccountBoxIcon fontSize="large" color="action"/>);
        case "ContactMail":
            return (<ContactMailIcon fontSize="large" color="action"/>);
        default:
            return (<AccountBoxIcon fontSize="large" color="action"/>);

    }
}

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function RegisterFormPage(props) {
    const { eventData, userRegistration } = props;

    const translationContainer = new TranslationContainer();
    let defaultLanguage = translationContainer.GetLanguage() || "en";

    const [regData, setRegData] = useState({fName:"", lName:"", countryID:"", state:"", city:"", email:"", phoneNumber:"", language:defaultLanguage, tosConfirm:false, privacyPolicyConfirm:false, sendConfirm:true});
    const [submitting, setSubmitting] = useState({status:0});
    const [formDisabled, setFormDisabled] = useState(false);
    const [tosModalOpen, setTosModalOpen] = React.useState(false);
    const [privacyModalOpen, setPrivacyModalOpen] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const aldersgateContext = useContext(AldersgateContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));
    const xsMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        maxHeight: '100%',
        overflow:'auto',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '40%' : '85%',
    };

    const { signupform } = eventData;
    let description = undefined;

    useEffect(() => {
        ValidateForm();
    }, [regData]);
    
    if(signupform !== undefined && signupform !== null && signupform.description !== "")
        description = signupform.description;

    const fieldChange = input => e => {
        setRegData({...regData, [input]: e.target.value });
      };

    const TextInputChange = (fieldName, value) => {
        setRegData(prevState => {
            let newState = {...prevState};
            newState[fieldName] = value;
            return {...newState};
        });
    }

    const countryChange = (values) => {
        let code = "";
        if(values && values.code)
            code = values.code;

        setRegData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
    };

    const languageChange = (e) => {
        let lang = "";
        if(e && e.value)
        lang = e.value;

        setRegData(prevState => {
            let newState = {...prevState};
            newState.language = lang;
            return {...newState};
        });
    }

    function OnSubmit() {

        if(!VerifyForm())
            return;

        setFormDisabled(true);
        console.log(regData);
        setSubmitting({status:1});

        aldersgateContext.PostToAPI('event/' + props.formID + '/participant', regData).then((success, err) => {
            if(success) {
                setSubmitting({status:2});
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/event/' + props.formID + '/participant/?api-key=foo', {
    }

    function VerifyForm() {
        if(regData.fName === "")
            return false;
        
        return true;
    }

    const OpenUserRegistration = () => {
        userRegistration(prev => {
            let newState = {...prev, ...regData, open:true};
            return newState;
        });
    }

    function SubmitButtonIcon(props) {
        switch (props.submitStatus) {
            case 0:
                return (<SendIcon />); 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                wait(500).then(() => setSubmitting({status:3}));
                return (<CheckIcon />);
            default:
                break;
        }
    }

    let lblFName = translationContainer.GetTranslation('tables', 'first-name', 'First Name');
    let lblLName = translationContainer.GetTranslation('tables', 'last-name', 'Last Name');
    let lblCountry = translationContainer.GetTranslation('tables', 'country', 'Country');
    let lblState = translationContainer.GetTranslation('tables', 'state-provence', 'State');
    let lblCity = translationContainer.GetTranslation('tables', 'city', 'City');
    let lblEmail = translationContainer.GetTranslation('tables', 'email', 'Email');
    let lblPhone = translationContainer.GetTranslation('tables', 'phone-number', 'Phone Number');
    let lblLanguage = translationContainer.GetTranslation('tables', 'language', 'Preferred Language');

    let field1 = {type:"text", order:0, group:0, size:6, fieldName:"fName", label:lblFName, defaultValue: "", required:true};
    let field2 = {type:"text", order:1, group:0, size:6, fieldName:"lName", label:lblLName, defaultValue: "", required:true};
    let field3 = {type:"country", order:2, group:0, size:12, fieldName:"countryID", label:lblCountry, defaultValue: "", required:true};
    let field4 = {type:"text", order:3, group:0, size:6, fieldName:"state", label:lblState, defaultValue: "", required:true};
    let field5 = {type:"text", order:4, group:0, size:6, fieldName:"city", label:lblCity, defaultValue: "", required:true};
    let field6 = {type:"text", order:5, group:1, size:6, fieldName:"email", label:lblEmail + " (example@email.com)", defaultValue: "", required:true};
    let field7 = {type:"text", order:6, group:1, size:6, fieldName:"phoneNumber", label:lblPhone + " (+55 555-555-5555)", defaultValue: "", required:true};
    let field8 = {type:"langdropdown", order:7, group:1, size:6, fieldName:"language", label:lblLanguage, defaultValue: defaultLanguage, options:[{value:'en', label:'English'}, {value:'es', label:'Spanish'}, {value:'fr', label:'French'}, {value:'pt', label:'Portuguese'}], required:true};
    

    let group1 = {name:translationContainer.GetTranslation('tables', 'general-info', 'General Information'), icon:"AccountBox", groupID:0, margin: 0};
    let group2 = {name:translationContainer.GetTranslation('tables', 'contact-details', 'Contact Details'), icon: "ContactMail", groupID:1, margin: 5};
    let sectionData = {groups: [group1, group2]};

    let formData = {type:1, sectionData: sectionData,fields: [field1, field2, field3, field4, field5, field6, field7, field8]};

    let parsed = ParseFormData(formData);

    const ValidateForm = () => {
        if(regData.fName === "" || regData.tosConfirm === false || regData.privacyPolicyConfirm === false) {
            setSubmitDisabled(true);
        } else {
            
            // if(EmailValid(regData.email) === false) {
            //     setSubmitDisabled(true);
            // } else {
            //     setSubmitDisabled(false);
            // }

            setSubmitDisabled(false);
        }
    }

    const EmailValid = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handelTOSCheckChanged = (event) => {
        let isChecked = event.target.checked;

        if(isChecked) {
            setTosModalOpen(true);
        } else {
            setRegData(prevState => {
                let newState = {...prevState};
                newState.tosConfirm = event.target.checked;
                return {...newState};
            });
        }

    }

    const handelPrivacyCheckChanged = (event) => {
        let isChecked = event.target.checked;

        if(isChecked) {
            setPrivacyModalOpen(true);
        } else {
            setRegData(prevState => {
                let newState = {...prevState};
                newState.privacyPolicyConfirm = event.target.checked;
                return {...newState};
            });
        }
    }

    const handelTOSClose = () => {
        setTosModalOpen(false);
    }

    const handelTOSAccept = () => {
        setRegData(prevState => {
            let newState = {...prevState};
            newState.tosConfirm = true;
            return {...newState};
        });
        setTosModalOpen(false);
        ValidateForm();
    }

    const handelPrivacyAccept = () => {
        setRegData(prevState => {
            let newState = {...prevState};
            newState.privacyPolicyConfirm = true;
            return {...newState};
        });
        setPrivacyModalOpen(false);
        ValidateForm();
    }

    const handelPrivacyClose = () => {
        setPrivacyModalOpen(false);
    }


    if(submitting.status < 3) {

        return (
            <Box m={1}>
                {description !== undefined ? <Box mb={4}>{description}</Box> : null}
                
                {parsed.groups.map((row, index) => {
                    return(
                        <Grid key={index} container sx={{height:'100%'}} columnSpacing={{ xs: 3 }}>
                            <Grid item xs={12} mb={1}>
                                <SectionTitle icon={row.icon} title={row.name} marginTop={row.margin} />
                            </Grid>
                            {row.fields.map((fieldRow, fieldIndex) => {
                                let fieldData = {...fieldRow, onChange:OnChange =>{}};
                                switch (fieldRow.type) {
                                    case "text":
                                        return (<RegFieldTextInput key={fieldRow.fieldName} fieldKey={fieldRow.fieldName} data={{...fieldData, fieldChange:TextInputChange}} disabled={formDisabled} />);
                                    case "country":
                                        return (<RegFieldCountrySelect key={fieldRow.fieldName} fieldKey={fieldRow.fieldName} data={fieldData} onChange={countryChange} disabled={formDisabled}/>);
                                    case "dropdown":
                                        return (<RegFieldDropdown key={fieldRow.fieldName} fieldKey={fieldRow.fieldName} data={{...fieldData}} onChange={languageChange} disabled={formDisabled} />);
                                    case "langdropdown":
                                        return (<RegLangDropdown key={fieldRow.fieldName} fieldKey={fieldRow.fieldName} data={{...fieldData}} onChange={languageChange} disabled={formDisabled} />);
                                    default:
                                        break;
                                }
                            })}
                        </Grid>
                    )
                })}

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} mt={4}>
                        <FormControlLabel required control={<Checkbox onChange={handelTOSCheckChanged} checked={regData.tosConfirm} disabled={formDisabled} />} label={translationContainer.GetTranslation('tables', 'login-read-tos', 'I have read and agree to ILI\'s Terms of Service')} />
                        <FormControlLabel required control={<Checkbox onChange={handelPrivacyCheckChanged} checked={regData.privacyPolicyConfirm} disabled={formDisabled} />} label={translationContainer.GetTranslation('tables', 'login-read-pp', 'I have read and agree to ILI\'s Privacy Policy')} />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={OnSubmit}
                            disabled={(formDisabled || submitDisabled)}
                            endIcon={<SubmitButtonIcon submitStatus={submitting.status}/>}>
                                <TranslationText page='tables' dbKey='register'>Register</TranslationText>
                        </Button>
                    </Grid>
                </Grid>
                
                <Modal
                open={tosModalOpen}
                onClose={handelTOSClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        <Box mb={10}>
                            <TOSView/>
                        </Box>
                    </Box>
                    <Box sx={{position:'absolute', bottom:10, width:'100%', display:'flex', gap:'20px', justifyContent:'center'}}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handelTOSClose}
                            endIcon={<CancelIcon />}>
                                <TranslationText page='tables' dbKey='disagree'>Disagree</TranslationText>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelTOSAccept}
                            endIcon={<CheckIcon />}>
                                <TranslationText page='tables' dbKey='accept'>Accept</TranslationText>
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={privacyModalOpen}
                onClose={handelPrivacyClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        <Box mb={10}>
                            <PrivacyView/>
                        </Box>
                    </Box>
                    <Box sx={{position:'absolute', bottom:10, width:'100%', display:'flex', gap:'20px', justifyContent:'center'}}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handelPrivacyClose}
                            endIcon={<CancelIcon />}>
                                <TranslationText page='tables' dbKey='disagree'>Disagree</TranslationText>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelPrivacyAccept}
                            endIcon={<CheckIcon />}>
                                <TranslationText page='tables' dbKey='accept'>Accept</TranslationText>
                        </Button>
                    </Box>
                </Box>
            </Modal>
            </Box>
        );
    } else {
        let name = translationContainer.GetTranslation('tables', 'reg-all-set', 'You\'re all set, {fname}!');
        name = name.replace("{fname}", regData.fName);
        
        let successText = translationContainer.GetTranslation('tables', 'reg-success-pt1', 'You\'ve successfully registered. Look out for more information from the training coordinator.');
        let extraInfo = translationContainer.GetTranslation('tables', 'reg-success-pt2', 'Watch ILI President, Daniel Drewski share the vision and mission of ILI.');

        const data = {successTitle:name, successText:successText, extraInfo:extraInfo}

        return (
            <RegSuccess data={data} OpenUserRegistration={OpenUserRegistration}/>
        );
    }

}