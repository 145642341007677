import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TranslationText from '../TranslationText';
import AddFacilitator from './AddFacilitator';
import ViewFacilitator from './ViewFacilitator';
import DFacilitatorList from './DFacilitatorList/DFacilitatorList';
import SessionModal from './SessionModal';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconedTextButton from '../IconedTextButton';
import AddIcon from '@mui/icons-material/Add';

export default function FacilitatorsDataGrid(props) {
    const {updateInformation } = props;
    const { facilitators, eventID } = props.eventData;
    const [formDisabled, setFormDisabled] = useState(true);
    const [facilitatorFormDisabled, setFacilitatorFormDisabled] = useState(true);
    const [formData, setFormData] = useState({id:0, adID:"", fName:"", lName:"", alias:"", email:"", phoneNumber:"", dropped:false, completed:false, language:"en", country:"", state:"", city:"", });
    const [value, setValue] = useState(0);

    const history = useHistory();
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        
        
    }, [value]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        overflow:'scroll',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '70%' : '85%',
        height:(smMatches) ? '70%' : '85%',
      };

    const columns = [
        { field: 'fName', headerName: 'First Name', flex: 1,editable: true },
        { field: 'lName', headerName: 'Last Name', flex: 1,editable: true },
        { field: 'email', headerName: 'Email', flex: 1,editable: true },
        { field: 'confirmed', headerName: 'Confirmed', type: 'boolean', flex: 1, editable: true }
    ];

    function handleInvite(event, name) {
        setFacilitatorFormDisabled(false);
    }

    const closeAddFacilitatorForm = () => {
        setFacilitatorFormDisabled(true);
    }

    const handleCloseForm = () => {
        setFormDisabled(true);
    }

    const EditFacilitatorDataForm = (disableForm) => {
    return (
        <Modal
            open={!disableForm.disableForm}
            onClose={handleCloseForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            
        >
            <Box sx={style}><ViewFacilitator data={formData} closeForm={handleCloseForm} updateInformation={updateInformation} /></Box>

        </Modal>
    );
    }

    const AddFacilitatorDataForm = (propsData) => {
        return (
            <Modal
                open={!propsData.disableForm}
                onClose={closeAddFacilitatorForm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}><AddFacilitator closeForm={closeAddFacilitatorForm} updateInformation={updateInformation} eventID={eventID}/></Box>
  
            </Modal>
        );
    }

    const handleRowClick = (params) => {
        setFormData(params);
        setFormDisabled(false);
      };
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Paper elevation={1}>
                <DFacilitatorList peopleListData={facilitators} onRowClick={handleRowClick} />
            </Paper>
            <AddFacilitatorDataForm disableForm={facilitatorFormDisabled} />
            
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
                <Box sx={{display:'flex', flexDirection:'row', alignContent:'flex-start'}}>
                    <Box mr={2} sx={{display:'flex', flexDirection:'row', alignContent:'flex-start', alignItems:'center', justifyContent:'flex-start'}} onClick={handleInvite}>
                        <Box>
                            <IconedTextButton  icon={<Avatar><AddIcon /></Avatar>} />
                        </Box>
                        <Box sx={{alignSelf:'center'}}>
                            <Typography align="left" variant="body2" color="action"><TranslationText page='tables' dbKey='add-facilitator'>Add Facilitator</TranslationText></Typography>
                        </Box>
                    </Box>

                    <EditFacilitatorDataForm disableForm={formDisabled} />
                </Box>
            </Box>
        </Box>
    )
}