import React, {useEffect, useState, useContext, createContext } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LanguageContext from '../context/LanguageContext';
import TestView from '../components/Test/TestView';
import TranslationContainer from '../localization/TranslationContainer';
import { Language } from "@material-ui/icons";


export default function TestPage(props) {
    //const [language, setLanguage] = React.useState('en');
    //const [reloadComps, setReloadComps] = React.useState(0);
    //const translationContainer = new TranslationContainer();
    //translationContainer.SetReloadComponents(setReloadComps);
    
    console.log("****Parent element is rendering");

    return (
        //<LanguageContext.Provider value={{language, setLanguage, translationContainer}}>
        <Box>
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <TestView />
                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
            </Grid>
        </Box>
        //</LanguageContext.Provider>
    )
  }