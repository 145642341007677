class TranslationContainer {
    //singleton instance
    static instance = null;

    constructor() {
        if(TranslationContainer.instance !== null) {
            return TranslationContainer.instance;
        } else {
            console.log("Creating new instance");
            TranslationContainer.instance = this;

            const userLanguage = this.GetStoredLanguage();
            this.language = userLanguage;

            console.log("browser language: " + userLanguage);
        }

        // Initialize any properties here
        this.pages = new Map();
        this.translations = new Map();
        this.DownloadTranslations = this.IngestTranslations.bind(this);
        this.ReloadComponents = null;
        this.awaitingPages = new Map();
        this.ChangeLanguageCallback = null;

        console.log("Translation container created");
    }

    GetStoredLanguage() {
        let langString = localStorage.getItem('lang'); //localStorage

        if(langString === null) {
            let userLanguage = navigator.language || navigator.userLanguage;

            console.log("browser language: " + userLanguage);

            if(userLanguage === null) {
                return "en";
            }

            //parse to get the language code
            let langParts = userLanguage.split('-');
            userLanguage = langParts[0];
            
            if(userLanguage === null) {
                return "en";
            }

            switch(userLanguage) {
                case "en":
                case "es":
                case "pt":
                case "fr":
                case "ru":
                    break;
                default:
                    userLanguage = "en";
                    break;
            }

            return userLanguage;
        }

        return JSON.parse(langString);
    }

    IngestTranslations(requestedPage) {
        if(this.awaitingPages.has(requestedPage)) {
            console.log("Page " + requestedPage + " is already being fetched");
            return;
        } else {
            this.awaitingPages.set(requestedPage, requestedPage);
        }

        return new Promise((resolve, reject) => {
            fetch('https://aldersgate-api.herokuapp.com'  + '/api/translation/get/' + this.language + '/' + requestedPage + '?api-key=foo', {
                method: 'get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {

                //console.log("Translation data: " + JSON.stringify(jsonData, null, 2));

                let page = jsonData.pageIdentifier;
                let translationData = [];

                if(jsonData?.translations !== undefined) {
                    jsonData.translations.forEach((tData) => {
                        translationData.push({key: tData.adID, value: tData.translation});
                    });
                }

                this.InsertPage(page, translationData, true);

                this.awaitingPages.delete(requestedPage);

                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    // Define methods here
    SetLanguage(newLanguage) {
        if(this.language === newLanguage) {
            console.log("Language already set to: " + newLanguage);
            return;
        } else {
            console.log("Setting language to: " + newLanguage);
            localStorage.setItem('lang', JSON.stringify(newLanguage));

            this.language = newLanguage;
            this.translations = new Map();
            this.awaitingPages = new Map();

            if(newLanguage === undefined || newLanguage === 'en') {
                if(this.ChangeLanguageCallback !== null) {
                    this.ChangeLanguageCallback(newLanguage);
                }

                return;
            }

            this.pages.forEach((page) => {
                console.log("Preloading Page: " + page);
                this.DownloadTranslations(page);
            });

            this.pages = new Map();
        }

        if(this.ChangeLanguageCallback !== null) {
            this.ChangeLanguageCallback(newLanguage);
        }
    }

    GetLanguage() {
        return this.language || "en";
    }

    SetReloadComponents(reloadComponents) {
        this.ReloadComponents = reloadComponents;
    }

    SetChangeLanguageCallback(callback) {
        this.ChangeLanguageCallback = callback;
    }

    InsertPage(pageIdentifier, translationData, force) {
        console.log("Inserting page: " + pageIdentifier);
        //console.log("Translation data: " + JSON.stringify(translationData, null, 2));

        if(!force && this.pages.has(pageIdentifier)) {
            console.log("Page already exists");
            return;
        }

        this.pages.set(pageIdentifier, pageIdentifier);

        let newMap = new Map();
        translationData.forEach((tData) => {
            //console.log("Inserting translation: " + tData.key + " value: " + tData.value);
            newMap.set(tData.key, tData.value);
        });

        this.translations[pageIdentifier] = newMap;

        if(this.ReloadComponents !== null) {
            this.ReloadComponents(prev => {
                let newCount = prev + 1;
                return newCount;
            });
        }

        // this.UpdateTranslationMap(map => {
        //     let newMap = new Map(map);
        //     translationData.forEach((tData) => {
        //         console.log("Inserting translation: " + tData.key + " value: " + tData.value);
        //         newMap.set(tData.key, tData.value);
        //     });

        //     new Map(map.set(pageIdentifier, newMap));
        // });
    }


    GetTranslation(page, key, fallback) {

        if(this.language == "en") {
            return (fallback || key);
        }

        if(this.translations === undefined) {
            console.log("No translations found");
            return (fallback || key);
        }

        if(this.translations[page] !== undefined && this.translations[page] !== null) {
            //console.log("Page " + page + " found in language container " + this.language);
            let pageMap = this.translations[page];

            if(pageMap.has(key)) {
                //console.log("Returning translation: " + pageMap.get(key));
                return pageMap.get(key);
            } else {
                console.log("Key " + key + " not found in page " + page);
                return (fallback || key);
            }

        } else {
            console.log("Page " + page + " not found in language container " + this.language);
            this.DownloadTranslations(page);
            return (fallback || key);
        }
    }

    GetLocalizedDate(dateString, options = { year: 'numeric', month: 'numeric', day: 'numeric' }) {
        let date = new Date(dateString);
        //let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(this.language, options);
    }

    GetLocalizedDateRange(startDate, endDate, options) {
        if(startDate === undefined || startDate === null || startDate === "") {
            return "??? - " + this.GetPrettyDate(endDate);
        }

        if(endDate === undefined || endDate === null || endDate === "") {
            return this.GetPrettyDate(startDate) + " - ???";
        }

        let options1 = { month: 'numeric', day: 'numeric' };
        let options2 = { month: 'numeric', day: 'numeric' };

        if(options !== undefined) {
            options1 = options;
            options2 = options;
        }

        let d1 = new Date(startDate);
        let d2 = new Date(endDate);

        //if d1 and d2 have different years
        if(d1.getFullYear() !== d2.getFullYear()) {
            options1.year = 'numeric';
            options2.year = 'numeric';
        }

        let date1 = this.GetLocalizedDate(d1, options1);
        let date2 = this.GetLocalizedDate(d2, options2);

        return date1 + " - " + date2;
    }

    GetPrettyDate(dateString) {
        if(dateString === undefined || dateString === null || dateString === "") {
            return "";
        }

        let date = new Date(dateString);
        return date.toLocaleDateString(this.language, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }

    GetLocalizedEventMedium(eventMedium) {
        switch(eventMedium) {
            case "In-Person":
                return this.GetTranslation('tables', 'in-person', 'In-Person');
            case "Online":
                return this.GetTranslation('tables', 'online', 'Online');
            case "hybrid":
                return this.GetTranslation('tables', 'hybrid', 'Hybrid');
            default:
                return eventMedium;
        }
    }

    GetLocalizedEventType(eventType) {
        switch(eventType) {
            case "Module 1":
                return this.GetTranslation('tables', 'hm1', 'Module 1');
            case "Module 2":
                return this.GetTranslation('tables', 'hm2', 'Module 2');
            case "History Makers Jounrey":
                return this.GetTranslation('tables', 'hmj', 'History Makers Journey');
            case "Mobilization":
                return this.GetTranslation('tables', 'mobilization', 'Flight School');
            default:
                return eventType;
        }
    }

}

//module.exports = TranslationContainer;
export default TranslationContainer;