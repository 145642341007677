import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationContainer from '../../localization/TranslationContainer';
import AuthContext from '../../context/AuthContext';
import PeopleFilterOptions from './PeopleFilterOptions';
import PeopleViewDatagrid from './PeopleViewDatagrid';
import Grid from '@mui/material/Grid';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function PeopleView(props) {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [value, setValue] = useState(0);
    const [filterData, setFilterData] = useState({ name: '', peopleLevel:"All"});
    const [peopleData, setPeopleData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);
    const authContext = useContext(AuthContext);

    const translationContainer = new TranslationContainer();
    
    useEffect(() => {
        SearchWithFilter();

    }, [value]);

    function ProcessPeople(people) {
        let peopleList = [];
        let keyCount = 0;
        people.forEach(person => {

            let chips = [];
            let facilitator = false;
            let graduated = false;
            let inProgress = false;
            let dropped = false;
            let highestLevel = "";

            if(person.event != undefined && person.event != null && person.event.length > 0) {
                person.event.forEach(event => {

                    console.log(JSON.stringify(event, null, 2));
                    if(event.completionStatus == 2) {
                        graduated = true;

                        if(event.eventType == "Module 1") {
                            if(highestLevel == "")
                                highestLevel = "Module 1";
                        }

                        if(event.eventType == "Module 2") {
                            if(highestLevel == "Module 1" || highestLevel == "")
                                highestLevel = "HMJ";
                        }

                        if(event.eventType == "HMJ") {
                            if(highestLevel == "Module 1" || highestLevel == "Module 2" || highestLevel == "")
                                highestLevel = "HMJ";
                        }
                    } else {
                        if(event.completionStatus == 1)
                            inProgress = true;

                        if(event.completionStatus == -1)
                            dropped = true;
                    }

                    if(event.facilitator == true) {
                        facilitator = true;
                    }

                });
            }

            if(facilitator) {
                chips.push({key:keyCount, type:translationContainer.GetTranslation('tables', 'facilitator', 'Facilitator')});
                keyCount++;
            }

            if(graduated) {
                chips.push({key:keyCount, type:highestLevel});
                keyCount++;
            } else {
                if(inProgress) {
                    chips.push({key:keyCount, type:translationContainer.GetTranslation('tables', 'in-training', 'In Training')});
                    keyCount++;
                } else {
                    if(!facilitator) {
                        chips.push({key:keyCount, type:translationContainer.GetTranslation('tables', 'not-graduated', 'Not Graduated')});
                        keyCount++;
                    }

                    if(dropped) {
                        chips.push({key:keyCount, type:translationContainer.GetTranslation('tables', 'dropped', 'Dropped')});
                        keyCount++;
                    }
                }
            }


            peopleList.push({
                id: person.adID,
                fName: person.fName,
                lName: person.lName,
                level: person.level,
                email: person.email,
                phoneNumber: person.phoneNumber,
                highestLevel: highestLevel,
                chips: chips,
                event: person.event
            });
        });

        return peopleList;
    }

    function HandleFilterOnChange(parameter, filterData) {
        setFilterData(prevState => {
            return {...prevState, [parameter]: filterData};
        });
    }

    function SearchWithFilter() {
        if(authContext?.accountState?.adID === undefined || authContext?.accountState?.adID === null) {
            return;
        }

        let query = '';
        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        if(filterData.peopleLevel !== 'All')
            query += '&level=' + filterData.peopleLevel;

        console.log(query);

        aldersgateContext.GetFromAPI('user/getlist/people/' + authContext?.accountState?.adID).then((success, err) => {

            if(success) {
                setPeopleData(ProcessPeople(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        //fetch(SophConstants.API_ADDR + '/api/user/getlist/people/' + token.adID + '?api-key=foo' + query, {
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PeopleFilterOptions search={SearchWithFilter} setFilterData={setFilterData}/>
                    <PeopleViewDatagrid peopleData={peopleData} />
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
            </Grid>
        </div>
    );
}