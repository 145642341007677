import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../context/aldersgate';
import AuthContext from '../../context/AuthContext';
import EventsCard from './EventsCard';

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function DashboardEvents(props) {
    const [eventsData, setEventsData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const authContext = React.useContext(AuthContext);
    const aldersgateContext = useContext(AldersgateContext);
    const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
    
    React.useEffect(() => {
      setAState(prev => {
          return({...authContext.accountState});
      });
    }, [authContext]);

    useEffect(() => {
        requestData(0);
    }, [aState]);

    const requestData =(retryCount) =>{

        if(aState.adID === undefined || aState.adID === null) {
            return;
        }

        aldersgateContext.GetFromAPI('event/getlist/events/' + aState.adID, 'sort=date&queryLimit=7', 1).then((success, err) => {

            if(success) {
                let eventsReturned = [];
                if(success != undefined)
                    eventsReturned = [...success];

                let count = 0;
                for(let i = 0; i < eventsReturned.length; i++) {
                    eventsReturned[i].id = eventsReturned[i].eventID;
                    count++;
                }
                
                setEventsData(eventsReturned);
                setLoaded(true);
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        /*if(retryCount < 10) {
            fetch(SophConstants.API_ADDR + '/api/event/getlist/events/' + aState.adID + '?api-key=foo', {
                method: 'get',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                body: null
            }).then((res) => {
                return res.json();
            }).then((jsonData, status) => {
                let eventsReturned = [];
                if(jsonData != undefined)
                    eventsReturned = [...jsonData];
                
                setEventsData(eventsReturned);
                setLoaded(true);

            }).catch((err) => {
                return wait(500).then(() => requestData(retryCount-1));
            });
        }*/
    }

    return (
        <div>
            <EventsCard data={eventsData} loaded={loaded} />
        </div>
    )
    }
//