import React, {useState, useEffect } from 'react';
import SophConstants from '../../constants/SophConstants';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import localizer from '../../localization/localizer';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CountrySelect from '../CountrySelect';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #444',
    p: 4,
    m:2,
  };

function LocalizedDateString(date) {
    var userLang = navigator.language || navigator.userLanguage;
    let dateToPrint = new Date(date);
    let dateString = dateToPrint.toLocaleDateString(userLang);

    return (dateString);
}

export default function CreateCertificate(props) {
    const {closeForm, eventData, eventID } = props;
    const [changedData, setChangedData] = useState({
        fName: null,
        lName: null,
        date: encodeURIComponent(LocalizedDateString(eventData.endingDate)),
        city: eventData.city,
        country: eventData.countryID,
        eventType: eventData.eventType,
    });

    const [formDisabled, setFormDisabled] = useState(true);

    const [certURL, setCertURL] = useState(SophConstants.API_ADDR + '/api/certificate/mcert/' + changedData.fName + '/' + changedData.lName + '/' + changedData.eventType + '/' + changedData.date + '/' + changedData.city + '/' + changedData.country + '/en?api-key=foo');

    const translationContainer = new TranslationContainer();

    useEffect(() => {
        setCertURL(prevState => {
            let language = translationContainer.GetLanguage();
            let newState = SophConstants.API_ADDR + '/api/certificate/mcert/' + changedData.fName + '/' + changedData.lName + '/' + changedData.eventType + '/' + changedData.date + '/' + changedData.city + '/' + changedData.country + '/' + language + '?api-key=foo';

            if(changedData.fName && changedData.lName && changedData.eventType && changedData.date && changedData.city && changedData.country) {
                setFormDisabled(false);
            } else {
                setFormDisabled(true);
            }

            return newState;
        });
    }, [changedData]);

    const handleCloseForm = () => {
        closeForm(true);
    }
    
    const handleTextChange = (event) => {
      setChangedData(prevState => {
          let newState = {...prevState};
          newState[event.target.id] = event.target.value;
          return {...newState};
      });
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
          setChangedData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
      };

    return (
        <Grid container>
            <Grid item xs={0} sm={1} md={2}></Grid>
            
            <Grid item xs={12} sm={10} md={8}>
                <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item sx={{textAlign:"center"}}>
                                <Typography component="h2" variant="h4" color="textSecondary"><TranslationText page='tables' dbKey='create-certificate'>Create a Certificate</TranslationText></Typography>
                                <TranslationText page='tables' dbKey='create-cert-instructions'>Complete the form below to download a certificate</TranslationText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}  sx={{mt:2}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={{mr:1}}>
                                <AssignmentIcon color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='participant-details'>Participant Details</TranslationText></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation('tables', 'first-name', 'First Name')}
                            id="fName"
                            value={changedData.fName}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation('tables', 'last-name', 'Last Name')}
                            id="lName"
                            value={changedData.lName}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    
                    <Grid item xs={0} sm={12} >
                    </Grid>

                    <Grid item xs={12}  sx={{mt:2}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={{mr:1}}>
                                <EventNoteIcon color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='event-details'>Event Details</TranslationText></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation('tables', 'city', 'City')}
                            id="city"
                            value={changedData.city}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CountrySelect handleCountryChange={handleCountryChange} currentCountry={changedData.country} />
                    </Grid>


                    <Grid item xs={12} mt={5}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="primary" href={certURL} disabled={formDisabled}><TranslationText page='tables' dbKey='download'>Download</TranslationText></Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleCloseForm} color="error"><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                                </Grid>
                            </Grid>

                        </Grid>
                </Grid>
                </Box>
        
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>
        </Grid>
      );
}

