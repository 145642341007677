import React from 'react';
import Box from '@mui/material/Box';
import TranslationText from '../../../TranslationText';
import CenteredLoading from "../../../CenteredLoading";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

export default function HeaderPreview(props) {

    const { eventData, selectedBanner, bannerOptions, changeBanner } = props;

    let signupForm = eventData.signupform;

    if(signupForm === undefined || signupForm === null) {
        signupForm = {bannerID:11};
    }

    let headerBannerSX = bannerOptions[selectedBanner]?.sx || bannerOptions[11]?.sx || {};
    headerBannerSX = {...headerBannerSX, ...{width:"100%", height:"100%", justifyContent: "center"}};

    function LocalizedDateString(date) {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    const ChangeHeaderBG = () => {
        if(changeBanner) {
            changeBanner();
        }
    }

    return (
        <Box sx={{width:'100%', height:200}}>
            <Box sx={headerBannerSX}>
            
                {(!props.eventData) ? (
                    <CenteredLoading />
                ) : (
                    <Box sx={{display:"flex", height:"100%", justifyContent: "center", alignItems:"center", flexDirection: "column", position:'relative'}}>
                        <Box sx={{position:'absolute', right:0, top:0}} m={1}>
                            <Button variant="contained" onClick={ChangeHeaderBG} startIcon={<EditIcon />}>
                            <TranslationText page='tables' dbKey='change'>Change</TranslationText>
                            </Button>
                        </Box>
                        <Box>
                            <Typography component="h1" variant="h3" style={{color:"white"}}>{props.eventData.eventName}</Typography>
                        </Box>
                        <Box>
                            <Typography component="h1" variant="h5" style={{color:"white"}}>{LocalizedDateString(props.eventData.startingDate)} - {LocalizedDateString(props.eventData.endingDate)}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}