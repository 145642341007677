import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function RegFieldTextInput(props) {
    const { fieldName, size, placeholderText, label, fieldChange, defaultValue } = props.data;
    const { fieldKey } = props;
    const [fieldValue, setFieldValue] = useState(defaultValue);
    const [allCaps, setAllCaps] = useState(false);
    const disabled = props.disabled;


    useEffect(() => {
        setFieldValue(defaultValue);
        setAllCaps(isAllCaps(defaultValue));
    }, [defaultValue]);

    //check if the value is all caps
    const isAllCaps = (str) => {
        //if the string is all numbers
        if(/^\d+$/.test(str)) {
            return false;
        }

        return str.length > 2 && str === str.toUpperCase();
    }

    //on text change
    const onTextChange = (event) => {
        let value = event.target.value;
        
        setFieldValue(value);
        setAllCaps(isAllCaps(value));
        fieldChange(fieldKey, value);
    }

    //convert to camel case
    const toCamelCase = (str) => {
        let str1 = str.toLowerCase();
        return str1.replace(/\b\w/g, l => l.toUpperCase());
    }

    const AcceptAllCaps = () => {
        setAllCaps(false);
        let value = toCamelCase(fieldValue);
        setFieldValue(value);
        fieldChange(fieldKey, value);
    }

    return (
        <Grid key={fieldKey+"-text-input"} item xs={12} sm={12} md={size} mt={2}>
            <Box>
            <Box>
            <TextField
            id={fieldKey}
            disabled={disabled}
            placeholder={placeholderText}
            label={label}
            onChange={onTextChange}
            value={fieldValue}
            margin="none"
            size="small"
            fullWidth
            variant="standard"
            /></Box>
            {allCaps ? <Box onClick={AcceptAllCaps}>
                <Typography variant="subtitle1" color="error">
                <Box>Use {toCamelCase(fieldValue)}? <CheckCircleIcon fontSize="small" sx={{verticalAlign:'middle', color:"green"}} /></Box>
                </Typography>
            </Box> : null}
            </Box>
        </Grid>
    )
}