import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import TranslationText from '../../TranslationText';
import TranslationContainer from '../../../localization/TranslationContainer';
import EditEventEmail from './EditEventEmail';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const DetailsContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[600],
      '&:hover': {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[600],
    },
  }));

const ActiveIndicator = (props) => {
    const {eventID, updateData, msgType, setSnackbarMessage, isActive} = props;

    const aldersgateContext = useContext(AldersgateContext);
    const translationContainer = new TranslationContainer();

    const handleChange = (event) => {
        //setIsActive(event.target.checked);

        aldersgateContext.PostToAPI('event/' + eventID + '/msgs/' + msgType, {msgEnabled:event.target.checked}, null, 1).then((success, err) => {
            if(success) {

                setSnackbarMessage("Message set to " + (!event.target.checked ? "active" : "inactive") + "!");

                updateData(prevState => {
                    let newState = {...prevState, msgRegisterConfirmationEnabled:event.target.checked};
                    return {...newState};
                });
            }
    
            if(err) {
                console.log("*****Error 1: " + err);

                //setSnackbarMessage("Message update error 1!");
            }
    
        }).catch((error) => {
            console.log("*****Error 2: " + error);

            //setSnackbarMessage("Message update error 2!");
        });
    }

    return(<FormControl component="fieldset" variant="standard" sx={{width:'300px'}}>
        <FormControlLabel
          control={
            <GreenSwitch checked={isActive} onChange={handleChange} name="gilad" />
          }
          label={isActive ? translationContainer.GetTranslation('tables', 'active', 'active') :translationContainer.GetTranslation('tables', 'inactive', 'Inactive')}
        />
    </FormControl>);
}

const DisplayMsgDetails = (props) => {
    const { msgData, showEdit, msgType } = props;

    console.log("props: " + JSON.stringify(props));

    function createMarkup(htmlData) {
        return {__html: htmlData};
    }

    if(msgData.messageData !== undefined && msgData.messageData !== null) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{
                        display: 'box',
                        alignItems: 'left',
                        textAlign: 'left',
                        flexWrap: 'wrap',
                    }}>
                        <div>
                            <Typography variant="body1" color="text.secondary">
                            <TranslationText page='tables' dbKey='subject'>Subject</TranslationText>:
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body2" color="text.secondary">
                                {msgData.messageData.commSubject}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'box',
                        alignItems: 'left',
                        textAlign: 'left',
                        flexWrap: 'wrap',
                    }}>
                        <Typography variant="body1" color="text.secondary">
                        <TranslationText page='tables' dbKey='message'>Message</TranslationText>: 
                        </Typography>
                        <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={createMarkup(msgData.messageData.commBody)}>
                            
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                            Add an automated email to this event.
                        </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" onClick={showEdit}>Add Email</Button>
                </Grid>
            </Grid>
        );
    }
}

export default function MessageDetails(props) {
    const { msgData, updateData, isActive, eventID, setSnackbarMessage, msgType } = props;
    const [showEditMessage, setShowEditMessage] = useState(false);
    const [messageData, setMessageData] = useState({});
    const [msgEnabled, setMsgEnabled] = useState(false);

    useEffect(() => {
        setMsgEnabled(isActive);
    }, [isActive]);

    useEffect(() => {
        setMessageData(msgData);
    }, [msgData]);

    const handleEditMessage = () => {
        setShowEditMessage(!showEditMessage);
    }

    const handleCancel = () => {
        setShowEditMessage(false);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="row" gap={1.5}>
                            <ActiveIndicator isActive={msgEnabled} eventID={eventID} msgType={msgType} updateData={updateData} setSnackbarMessage={setSnackbarMessage} />
                            {!showEditMessage ? <Button variant="contained" size="small" onClick={handleEditMessage}><TranslationText page='tables' dbKey='edit-message'>Edit Message</TranslationText></Button> : null}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            {showEditMessage ? <EditEventEmail msgData={messageData} eventID={eventID} msgType={msgType} handleCancel={handleCancel} setSnackbarMessage={setSnackbarMessage}/> : <DisplayMsgDetails msgData={msgData} showEdit={handleEditMessage} msgType={msgType} /> }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}