import React from 'react';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { IconedText, RegisteredProgress } from '../';
import Typography from '@mui/material/Typography';
import ListAltIcon from '@mui/icons-material/ListAlt';

import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import GroupIcon from '@mui/icons-material/Group';
import StreetviewIcon from '@mui/icons-material/Streetview';



export default function EventInfoContainer(props) {
    const eventMediumTitle = props.eventData.eventMedium + " Event";
    var participantCount = 0;
    var participantMax = 30;

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const translationContainer = new TranslationContainer();
    
    if(props.eventData.participants) {
        participantCount = props.eventData.participants.length;
    }

    if(props.eventData.maxParticipants) {
        participantMax = props.eventData.maxParticipants;
    }

    const GetProgressTitle = (registered, max) => {
        return registered + " " + translationContainer.GetTranslation("tables", "registered", "registered") + " / " + max;
    }

    const GetMediumIcon = (medium, size) => {
        switch (medium) {
          case "Online":
            return <TapAndPlayIcon sx={{fontSize: size}} color="action" />;
            case "In-Person":
              return <GroupIcon sx={{fontSize: size}} color="action" />;
              case "Hybrid":
                return <StreetviewIcon sx={{fontSize: size}} color="action" />;
          default:
            break;
        }
    }
    
    
    const GetMediumText = (medium) => {
        switch (medium) {
          case "Online":
            return translationContainer.GetTranslation("tables", "event-info-online", "This event takes place online");
            case "In-Person":
              return translationContainer.GetTranslation("tables", "event-info-in-person", "This is an In-Person event");
              case "Hybrid":
                return translationContainer.GetTranslation("tables", "event-info-hybrid", "Part in-person and part online");
          default:
            break;
        }
    }

    const GetEventMediumTitle = (medium) => {
        switch (medium) {
            case "Online":
                return translationContainer.GetTranslation("tables", "online", "Online Event");
            case "In-Person":
                return translationContainer.GetTranslation("tables", "in-person", "In-Person Event");
            case "Hybrid":
                return translationContainer.GetTranslation("tables", "hybrid", "Hybrid Event");
            default:
                break;
        }
    }

    const RenderRegistrationStstus = () => {
        if(props.eventData.eventClosed) {
            return (
                <IconedText icon={<ListAltIcon sx={{fontSize: 45}} color="action" />} title={translationContainer.GetTranslation("tables", "reg-closed", "Registration Closed")}><TranslationText page='tables' dbKey='cannot-register'>Participants cannot register</TranslationText></IconedText>
            );
        } else {
            return (
                <IconedText icon={<ListAltIcon sx={{fontSize: 45}} color="action" />} title={translationContainer.GetTranslation("tables", "reg-open", "Registration Open")}><TranslationText page='tables' dbKey='can-register'>Participants can register for this event!</TranslationText></IconedText>
            )
        }
    }

    if(smMatches) {
    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <RenderRegistrationStstus />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <IconedText icon={GetMediumIcon(props.eventData.eventMedium, 45)} title={GetEventMediumTitle(props.eventData.eventMedium)}>{GetMediumText(props.eventData.eventMedium)}</IconedText>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <RegisteredProgress title={GetProgressTitle(participantCount, participantMax)} registered={participantCount} max={participantMax}/>
            </Grid>
        </Grid>
    );
    } else {
        return (
            <Grid container mb={1} mt={1}>
                <Grid item xs={12}>
                    <Box mr={2} sx={{display:'flex', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start', justifyContent:'flex-start'}}>
                        <IconedText renderSize="small" icon={<ListAltIcon sx={{fontSize: 35}} color="action" />} title={translationContainer.GetTranslation("tables", "reg-open", "Registration Open")}>Participants can register for this event!</IconedText>
                        <IconedText renderSize="small" icon={GetMediumIcon(props.eventData.eventMedium, 35)} title={eventMediumTitle}>{GetMediumText(props.eventData.eventMedium)}</IconedText>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                <Box mr={2} sx={{width:1}}>
                    <RegisteredProgress title={GetProgressTitle(participantCount, participantMax)} registered={participantCount} max={participantMax}/>
                </Box>
                </Grid>
            </Grid>
        );
    }
}