import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import TranslationText from '../TranslationText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DoneIcon from '@mui/icons-material/Done';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export default function RegSuccess(props) {
    const {OpenUserRegistration} = props;
    const {successTitle, successText, extraInfo} = props.data;

    const history = useHistory();

    const redirectToCreateAccount = () => {
        history.push("/user/register");
    }

    return (
        <Fade in={true}>
        <Card sx={{minWidth:155}}>
                <CardContent>
                    <Typography color="secondary" gutterBottom variant="h4" component="h2">
                    <DoneIcon fontSize="large"/> {successTitle}
                    </Typography>
                    <Typography color="textSecondary" variant="h6" component="h2">
                        {successText}
                    </Typography>
                    <Box mt={4}>
                        <Button variant="contained" size="large" endIcon={<AccountBoxIcon />} onClick={OpenUserRegistration}>
                            <TranslationText page='tables' dbKey='create-account'>Create an account</TranslationText>
                        </Button>
                    </Box>
                    <Box pt={10} >
                        <Box sx={{position:'relative', overflow:'hidden', width:"100%", paddingTop:'56.25%'}}> 
                            <Box sx={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}}>
                                <iframe width="100%" height="100%" loading="lazy" src="https://www.youtube.com/embed/dZmVGpX5A5w?si=Y8QT5eft41goBroV" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Box>
                        </Box>
                        <Typography color="textSecondary" variant="h6" component="h2">
                            {extraInfo}
                        </Typography>
                    </Box>
                </CardContent>
        </Card>
        </Fade>
    );
}

//<iframe src="https://player.vimeo.com/video/401122105?h=f0f40b290e&color=3573b9&title=0&byline=0&portrait=0" width="100%" height="338" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>