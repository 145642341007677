import React, {useEffect, useState, useContext} from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import AuthContext from "../../context/AuthContext";
import HomeFeed from './DashboardHome/HomeFeed';
import ViewRegisteredEvents from './DashboardHome/ViewRegisteredEvents';
import { DashboardEvents, GlobalTrainedCount, GlobalStatsGraph } from "./../";
import LeadersTrainedGraph from "./LeadersTrainedGraph";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function DashboardView(props) {
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

    const [dashboardFeed, setDashboardFeed] = useState({ownedEvents:false, registeredEvents:false, stats:false, feed:[]});

    const authContext = useContext(AuthContext);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {

        if(authContext?.accountState?.loggedIn) {
            aldersgateContext.GetFromAPI('user/' + authContext?.accountState?.token + '/feed').then((success, err) => {

                if(success) {
                    setDashboardFeed(success);
                    //console.log("dashboard feed Success: " + JSON.stringify(success, null, 2));
                } else {
                    console.log("dashboard Error: " + err);
                }
        
                if(err) {
                    console.log("dashboard Error: " + err);
                }
        
            }).catch((error) => {
                console.log("dashboard Error: " + error);
            });
        }

    }, [authContext]);

    useEffect(() => {

    }, [dashboardFeed]);

    return (
        <Grid container spacing={3}>
            {dashboardFeed.stats ? (
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}  md={8}>
                        <Paper variant="outlined">
                            <LeadersTrainedGraph year={2023}/>
                        </Paper>
                    </Grid>

                    {mdMatches ? 
                        <Grid item xs={12} md={4}>
                            <Paper variant="outlined">
                                <GlobalTrainedCount />
                            </Paper>
                        </Grid>
                    : null}

                </Grid>
            </Grid>
            ) : null}

            {dashboardFeed.ownedEvents ? (
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <DashboardEvents />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            ) : null}

            {!dashboardFeed.registeredEvents ? (
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <ViewRegisteredEvents />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            ) : null}

            <Grid item xs={12}>
                <HomeFeed feed={dashboardFeed.feed} />
            </Grid>
        </Grid>
    );
}

/*
<Grid container spacing={3}>
                    <Grid item xs={12} lg={7}>
                        <Paper variant="outlined">
                            <VimeoCard videoLink="802856044" title="ILI Impact 2022" description="You are a part of an extraordinary team, and we are excited to share with you some great news from 2022!" buttonText = "Share"/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Paper variant="outlined">
                            <ImageCard imageFile="water1.png" title="One Curriculum" description="Our new unified training experience. History Makers consists of teaching sessions and workshops designed to equip leaders of different ages and leadership levels from various cultural and social backgrounds." buttonText = "Share"/>
                        </Paper>
                    </Grid>
                </Grid>
                */