import React, {useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LanguageContext from '../../context/LanguageContext';
import TranslationText from '../TranslationText';
import TranslationContainer from '../../localization/TranslationContainer';

export default function TestPage(props) {

    //const [language, setLanguage] = React.useState('en');
    //get context
    const {language, setLanguage, translationContainer} = useContext(LanguageContext);

    const handleChange = (event) => {
      setLanguage(event.target.value);
      translationContainer.SetLanguage(event.target.value);
      //const translationContainer = new TranslationContainer();

    //   if(event.target.value === 'es'){
    //     let pageData = [];
    //     pageData.push({key: 'test-title', value: 'Título de prueba '});
    //     pageData.push({key: 'test-subtitle', value: 'Subtítulo de prueba '});

    //     translationContainer.InsertPage('test', [...pageData], true);
    //   } else if(event.target.value === 'pt'){
    //     let pageData = [];
    //     pageData.push({key: 'test-title', value: 'Teste Título '});
    //     pageData.push({key: 'test-subtitle', value: 'Teste Subtítulo '});

    //     translationContainer.InsertPage('test', [...pageData], true);
    //   }
    };

    console.log("****This element is rendering");

    return (
        <Box>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                <Box sx={{ width: 120 }} mt={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        label="Language"
                        onChange={handleChange}
                        >
                        <MenuItem value='en'>English</MenuItem>
                        <MenuItem value='es'>Spanish</MenuItem>
                        <MenuItem value='pt'>Portuguese</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div">
                        <TranslationText page='tables' dbKey='view'>Test</TranslationText>
                    </Typography>
                    <TranslationText page='tables' dbKey='global'>Test</TranslationText>
                    {translationContainer.GetTranslation('tables', 'view', 'Test')}
                </Grid>
            </Grid>

        </Box>
    )
  }

  //{translationContainer.GetTranslation('tables', 'sort-by', 'Sort By')}
  //<TranslationText page='tables' dbKey='sort-by'>Sort By</TranslationText>