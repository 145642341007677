
import React from 'react';
import TranslationContainer from '../../localization/TranslationContainer';
import Box from '@mui/material/Box';
import CenteredLoading from "../CenteredLoading";
import Typography from '@mui/material/Typography';


const BannerOptions = [
    {value: 0, sx:{position:"relative",
        margin:"0 auto",
        background: '#3573b9',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/reghero.png'})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:"cover",
        justifyContent: "center"}, 
    label: "Image"},
    {value: 1, sx:{background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)'}, label: "Gradient"},
    {value: 2, sx:{background: 'linear-gradient(to right bottom, #4158D0, #C850C0, #FFCC70)'}, label: "Gradient"},
    {value: 3, sx:{background: 'linear-gradient(to right bottom, #8EC5FC, #E0C3FC)'}, label: "Gradient"},
    {value: 4, sx:{background: 'linear-gradient(to right bottom, #FF3CAC, #784BA0, #2B86C5)'}, label: "Gradient"},
    {value: 5, sx:{background: 'linear-gradient(to right bottom, #FBAB7E, #F7CE68)'}, label: "Gradient"},
    {value: 6, sx:{background: 'linear-gradient(to right bottom, #FEB692, #EA5455)'}, label: "Gradient"},
    {value: 7, sx:{background: 'linear-gradient(to right bottom, #F093FB, #F5576C)'}, label: "Gradient"},
    {value: 8, sx:{background: 'linear-gradient(to right bottom, #5EE7DF, #B490CA)'}, label: "Gradient"},
    {value: 9, sx:{background: '#3573b9'}, label: "Solid"},
    {value: 10, sx:{background: '#aaaaaa'}, label: "Solid"},
    {value: 11, sx:{background: '#ffffff'}, label: "Solid"},
];

function RegHeaderNameCard(props) {

    function LocalizedDateString(date) {
        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    return (
        <div>
            {(!props.isLoaded) ? (
                    <CenteredLoading />
                  ) : (
                    <div>
                    <Typography component="h2" variant="h4" color="textSecondary">{props.eventData.eventName}</Typography>
                    <Typography component="h2" variant="h6" color="textSecondary">{LocalizedDateString(props.eventData.startingDate)} - {LocalizedDateString(props.eventData.endingDate)}</Typography>
                    </div>
                  )}
        </div>
    );
}


export default function RegHeader(props) {

    const { eventData, defautWhite } = props;

    const translationContainer = new TranslationContainer();

    let signupForm = eventData.signupform;

    if(signupForm === undefined || signupForm === null) {
        if(defautWhite) {
            signupForm = {bannerID:11};
        } else {
            signupForm = {bannerID:0};
        }
    }

    let headerBannerSX = BannerOptions[signupForm.bannerID].sx;
    headerBannerSX = {...headerBannerSX, ...{width:"100%", height:"100%", justifyContent: "center"}};

    function LocalizedDateString(date) {
        return translationContainer.GetLocalizedDate(date);

        var userLang = navigator.language || navigator.userLanguage;
        let dateToPrint = new Date(date);
        let dateString = dateToPrint.toLocaleDateString(userLang);

        return (dateString);
    }

    return (
        <Box sx={{width:'100%', height:200}}>
            <Box sx={headerBannerSX}>
            {(!props.isLoaded) ? (
                    <CenteredLoading />
                  ) : (
                    <div style={{display:"flex", height:"100%", justifyContent: "center", alignItems:"center", flexDirection: "column"}}>
                        <div>
                            <Typography component="h1" variant="h3" style={{color:"white"}}>{eventData.eventName}</Typography>
                        </div>
                        <div>
                            <Typography component="h1" variant="h5" style={{color:"white"}}>{LocalizedDateString(eventData.startingDate)} - {LocalizedDateString(eventData.endingDate)}</Typography>
                        </div>
                    </div>
                  )}
            </Box>
        </Box>
    )
}