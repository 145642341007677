import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../context/aldersgate';
import TranslationContainer from '../../localization/TranslationContainer';
import TranslationText from '../TranslationText';
import Box  from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import NativeSelect from '@mui/material/NativeSelect';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CountrySelect from '../CountrySelect';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #444',
    p: 4,
    m:2,
  };

export default function AddFacilitator(props) {
    const {closeForm, updateInformation, eventID } = props;
    const {updateData, setSnackbarMessage } = updateInformation;
    const aldersgateContext = useContext(AldersgateContext);

    const translationContainer = new TranslationContainer();
    
    const [changedData, setChangedData] = useState({
        "fName": "",
        "lName": "",
        "email": "",
        "phoneNumber": "",
        "city": "",
        "state": "",
        "countryID": "",
        "confirmed": true,
    });
    const [value, setValue] = useState(0);

    const handleCloseForm = () => {
        closeForm(true);
    }
    
    const handleTextChange = (event) => {
      let value = {[event.target.id]: event.target.value};
    
      setChangedData(prevState => {
          let newState = {...prevState};
          newState[event.target.id] = event.target.value;
          return {...newState};
      });
    }

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
          code = values.code;
    
          setChangedData(prevState => {
            let newState = {...prevState};
            newState.countryID = code;
            return {...newState};
        });
      };
    
    const handleSubmit = () => {
      let editedData = {...changedData};
      editedData.id = undefined;

    aldersgateContext.PostToAPI('event/' + eventID + '/facilitator', editedData, null, 1).then((success, err) => {
        if(success) {
            updateData(prevState => {
                let newState = prevState;
                newState = newState+1;
                return {...newState};
              });
              
            setSnackbarMessage("Facilitator Added: " + (editedData.fName ? editedData.fName : "null") + " " +  (editedData.lName ? editedData.lName : "null") );
        }

        if(err) {
            console.log("Error: " + err);
        }

    }).catch((error) => {
        console.log("Error: " + error);
    });

      //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/facilitator/' + '?api-key=foo', {
    
    }

    return (

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item sx={{textAlign:"center"}}>
                                <Typography component="h2" variant="h4" color="textSecondary"><TranslationText page='tables' dbKey='add-a-facilitator'>Add a Facilitator</TranslationText></Typography>
                                <TranslationText page='tables' dbKey='facilitator-add-instructions'>Complete the form below to add a facilitator to the event</TranslationText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}  sx={{mt:2}}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item sx={{mr:1}}>
                            <AssignmentIcon color="action"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='contact-details'>Contact Details</TranslationText></Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "first-name", "First Name")}
                            id="fName"
                            value={changedData.fName}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "last-name", "Last Name")}
                            id="lName"
                            value={changedData.lName}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    
                    <Grid item xs={0} sm={12} >
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "email", "Email")}
                            id="email"
                            value={changedData.email}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "phone-number", "Phone Number")}
                            id="phoneNumber"
                            value={changedData.phoneNumber}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>

                    <Grid item xs={0} sm={12} >
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CountrySelect handleCountryChange={handleCountryChange} currentCountry={changedData.countryID}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "state-provence", "State/Provence")}
                            id="state"
                            value={changedData.state}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            label={translationContainer.GetTranslation("tables", "city", "City")}
                            id="city"
                            value={changedData.city}
                            sx={{  width: '100%' }}
                            onChange={handleTextChange}
                        />
                    </Grid>


                    <Grid item xs={12}  sx={{mt:2}}>

                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={{mr:1}}>
                                <AssessmentIcon color="action"/>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" align="left" color="textSecondary"><TranslationText page='tables' dbKey='event-details'>Event Details</TranslationText></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="dropped">
                            <TranslationText page='tables' dbKey='confirmed'>Confirmed</TranslationText>
                            </InputLabel>
                            <NativeSelect
                                value={changedData.confirmed}
                                onChange={handleTextChange}
                                sx={{  width: '100%' }}
                                inputProps={{
                                name: 'confirmed',
                                id: 'confirmed',
                                }}
                            >
                                <option value={true}>{translationContainer.GetTranslation("tables", "yes", "Yes")}</option>
                                <option value={false}>{translationContainer.GetTranslation("tables", "no", "No")}</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} mt={5}>

                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="success" onClick={handleSubmit}><TranslationText page='tables' dbKey='save'>Save</TranslationText></Button>
                                </Grid>
                                
                                <Grid item>
                                    <Button variant="contained" onClick={handleCloseForm} color="error"><TranslationText page='tables' dbKey='cancel'>Cancel</TranslationText></Button>
                                </Grid>
                            </Grid>

                        </Grid>
                </Grid>
      );
}

